'use strict'
import Api from '@/api/abstract'
import { APP_TOKEN_NAME } from '@/config/constants'

export let GET_LIST_REGISTER_INFO = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/register-plugin?${params.payload.query}`
  let headers = {
    Authorization: `${window.$cookies.get(APP_TOKEN_NAME)}`
  }
  return new Api(url, 'get', successCallback, errorCallback, { headers })
}

export let GET_ALL_REGISTER_INFO = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/register-plugin?page=1&limit=1000`
  let headers = {
    Authorization: `${window.$cookies.get(APP_TOKEN_NAME)}`
  }
  return new Api(url, 'get', successCallback, errorCallback, { headers })
}

export let GET_REGISTER_INFO_BY_ID = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/register-plugin/${params.payload.id}`
  let headers = {
    Authorization: `${window.$cookies.get(APP_TOKEN_NAME)}`
  }
  return new Api(url, 'get', successCallback, errorCallback, { headers })
}

export let CREATE_REGISTER_INFO = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/register-plugin`
  let headers = {
    Authorization: `${window.$cookies.get(APP_TOKEN_NAME)}`
  }
  let data = params.payload.data
  return new Api(url, 'post', successCallback, errorCallback, { headers, data })
}

export let UPDATE_REGISTER_INFO = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/register-plugin/${params.payload.data.id}`
  let headers = {
    Authorization: `${window.$cookies.get(APP_TOKEN_NAME)}`
  }
  let data = params.payload.data
  return new Api(url, 'put', successCallback, errorCallback, { headers, data })
}

export let UPDATE_EXPIRED_DATE = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/register-plugin/${params.payload.data.id}`
  let headers = {
    Authorization: `${window.$cookies.get(APP_TOKEN_NAME)}`
  }
  let data = params.payload.data
  return new Api(url, 'PATCH', successCallback, errorCallback, { headers, data })
}

export let DELETE_REGISTER_INFO = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/register-plugin/${params.payload.id}`
  let headers = {
    Authorization: `${window.$cookies.get(APP_TOKEN_NAME)}`
  }
  return new Api(url, 'delete', successCallback, errorCallback, { headers })
}

export let CHANGE_STATUS_REGISTER_INFO = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/register-plugin/${params.payload.id}`
  let headers = {
    Authorization: `${window.$cookies.get(APP_TOKEN_NAME)}`
  }
  return new Api(url, 'patch', successCallback, errorCallback, { headers })
}
