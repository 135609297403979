<template>
  <Main>
    <template #body>
      <Card class="page-plan">
        <template #header>
          {{ isEdit ? $t('menu.plugin.edit') : $t('menu.plugin.add')}}
        </template>
        <template #body>
          <form>
            <el-row :gutter="20">
              <el-col :span="8" :xl="8" :lg="10" :xs="24">
                <InputField>
                  <template #label>{{$t('pluginManagement.id')}}</template>
                  <template #input>
                    <InputText
                        v-model="pluginItem.id"
                        disabled
                    ></InputText>
                  </template>
                </InputField>
              </el-col>
              <el-col :span="8" :xl="8" :lg="10" :xs="24" v-if="isEdit">
                <InputField classes="mb-2">
                  <template #label>
                    {{$t('pluginManagement.pluginCode')}}
                    <span class="text-danger"> *</span>
                  </template>
                  <template #input>
                    <InputText disabled
                               v-model="pluginItem.code"
                               @change="validate('code')"></InputText>
                  </template>
                  <template #error v-if="errorInfo.code">{{errorInfo.code}}</template>
                </InputField>
              </el-col>
              <el-col :span="8">
                <InputField>
                  <template #label>{{$t('pluginManagement.name')}}
                    <span class="text-danger"> *</span>
                  </template>
                  <template #input>
                    <InputText
                        v-model="pluginItem.name"
                        @keydown="validate('name')"
                        @keyup="validate('name')"
                    ></InputText>
                  </template>
                  <template #error v-if="errorInfo.name">
                    {{errorInfo.name}}
                  </template>
                </InputField>
              </el-col>
            </el-row>
            <el-divider content-position="left" class="mt-2">
              <i class="el-icon-star-on"></i>
            </el-divider>
            <el-row :gutter="20">
              <el-col :span="8">
                <InputField classes="mb-2">
                  <template #label>{{$t('pluginManagement.developer')}}
                  </template>
                  <template #input>
                    <InputText
                        v-model="pluginItem.developer"
                    ></InputText>
                  </template>
                </InputField>
              </el-col>
              <el-col :span="8">
                <InputField classes="mb-2">
                  <template #label>{{$t('pluginManagement.lastVersion')}}
                  </template>
                  <template #input>
                    <InputText
                        v-model="pluginItem.last_version"
                    ></InputText>
                  </template>
                </InputField>
              </el-col>
              <el-col :span="8">
                <InputField classes="mb-2">
                  <template #label>{{$t('pluginManagement.createdYear')}}
                  </template>
                  <template #input>
                    <el-date-picker v-model="pluginItem.created_year" format="yyyy/MM"></el-date-picker>
                  </template>
                </InputField>
              </el-col>
            </el-row>
            <el-divider content-position="left">
              <i class="el-icon-star-on"></i>
            </el-divider>
            <el-row :gutter="20">
              <el-col :span="8" :xl="8" :xs="12" :lg="10">
                <InputField classes="mb-3">
                  <template #label>
                    <div>
                      {{$t('pluginManagement.pricePerMonth')}}
                      <span class="text-danger"> *</span>
                    </div>
                  </template>
                  <template #input>
                    <InputNumber
                        @keydown.native="validate('price_per_month')"
                        @keyup.native="validate('price_per_month')"
                        prevText="円"
                        v-model="pluginItem.price_per_month"></InputNumber>
                  </template>
                  <template #error v-if="errorInfo.price_per_month">
                    {{errorInfo.price_per_month}}
                  </template>
                </InputField>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="16" :xl="16" :lg="20" :xs="24">
                <InputField classes="mb-2" :span-label="3" :span-input="21">
                  <template #label>
                    {{$t('pluginManagement.description')}}
                  </template>
                  <template #input>
                      <textarea
                          class="form-control"
                          style="margin-left: -3px"
                          v-model="pluginItem.description"
                          :rows="4">
                      </textarea>
                  </template>
                </InputField>
              </el-col>
            </el-row>

            <div class="text-right mt-3">
              <Button
                  v-loading="isLoadingDelete"
                  :disabled="isLoadingDelete"
                  v-if="isEdit && !isDisabled"
                  classes="btn btn-danger mr-3"
                  @click="onDeleteRecord(pluginItem)">
                {{$t('button.delete')}}
              </Button>
              <Button
                  classes="btn btn-outline-primary mr-3"
                  type="cancel"
                  @click="cancel">
                {{$t('button.cancel')}}
              </Button>
              <Button
                  v-loading="isLoading"
                  :disabled="isLoading"
                  classes="btn btn-primary"
                  type="submit"
                  @click="createPlan">
                {{isEdit? $t('button.save') :$t('button.register')}}
              </Button>
            </div>
          </form>
        </template>
      </Card>
    </template>
  </Main>
</template>

<script>
import Main from '@/components/partials/layout/Main'
import Card from '@/components/partials/Card'
import InputField from '@/components/partials/InputField'
import InputText from '@/components/partials/forms/InputText'
import InputNumber from '@/components/partials/forms/InputNumber'
import Button from '@/components/partials/Button'
export default {
  name: 'staffManagement',
  components: { Button, InputField, Card, Main, InputText, InputNumber },
  data () {
    return {
      isEdit: false,
      listPlane: [],
      isLoading: false,
      isDisabled: false,
      isLoadingDelete: false,
      pluginItem: {
        name: '',
        id: '',
        code: '',
        description: '',
        developer: '',
        last_version: '',
        created_year: '',
        price_per_month: ''
      },
      errorInfo: {
        name: '',
        id: '',
        code: '',
        description: '',
        developer: '',
        last_version: '',
        created_year: '',
        price_per_month: ''
      }
    }
  },
  head: {
    title: function () {
      return {
        inner: this.$route.query.id ? this.$t('menu.plugin.edit') : this.$t('menu.plugin.add')
      }
    }
  },
  created () {
    if (this.$route.query.id) {
      this.isEdit = true
      this.getPlanById(this.$route.query.id)
    }
    this.$store.commit('setBreadCrumb', [
      { label: 'pluginManagement', link: 'pluginsManagement' },
      { label: this.isEdit ? 'plugin.edit' : 'plugin.add' }])
  },
  methods: {
    getPlanById (idPlan) {
      let successCallback = (response) => {
        if (response.status === 200) {
          this.pluginItem = response.data.data
          if (this.pluginItem && this.pluginItem['RegisterInfos']) {
            if (this.pluginItem['RegisterInfos'].length > 0) {
              this.isDisabled = true
            }
          }
        }
      }
      let errorCallback = () => {}
      let payload = {
        successCallback,
        errorCallback,
        id: idPlan
      }
      this.$store.dispatch('plugin/getPluginById', payload)
    },
    cancel () {
      this.$router.push({ name: 'pluginsManagement' })
    },

    onDeleteRecord (item) {
      this.$confirm(this.$t('pluginManagement.msgConfirmDelete'), this.$t('msg.titleSuccess'), {
        confirmButtonText: this.$t('button.ok'),
        cancelButtonText: this.$t('button.cancel'),
        type: 'warning'
      }).then(() => {
        let successCallback = (response) => {
          if (response.status === 200) {

          }
        }
        let errorCallback = () => {
          this.isLoadingDelete = false
        }
        let payload = {
          id: item._id,
          successCallback,
          errorCallback
        }
        this.$store.dispatch('plugin/deletePlugin', payload)
      }).catch(() => {
        this.isLoadingDelete = false
      })
    },

    createPlan () {
      let check = this.validate()
      if (check) {
        this.isLoading = true
        let successCallback = (response) => {
          if (response.status === 200) {
            this.isLoading = false
            this.$message({
              showClose: true,
              message: !this.isEdit ? this.$t('pluginManagement.msgAddSuccess') : this.$t('pluginManagement.msgUpdateSuccess'),
              type: 'success'
            })
            this.$router.push({ name: 'pluginsManagement' })
          }
        }
        let errorCallback = () => {
          this.isLoading = false
        }
        let data = Object.assign({}, this.pluginItem)
        data.price_per_month = Number(this.pluginItem.price_per_month)
        data.id_plugin_kintone = Math.floor((Math.random() * 899999) + 100000) + ''
        let payload = {
          successCallback,
          errorCallback,
          data: data,
          id: ''
        }
        let action = this.isEdit ? 'plugin/updatePlugin' : 'plugin/createPlugin'
        this.$store.dispatch(action, payload)
      }
    },

    validate (field) {
      let check = true
      let { pluginItem } = this
      if (field === 'name' || !field) {
        if (pluginItem.name === '' || !pluginItem.name) {
          this.errorInfo.name = this.$t('pluginManagement.msgNameRequired')
          check = false
        } else {
          this.errorInfo.name = ''
        }
      }
      if (field === 'price_per_month' || !field) {
        if (pluginItem.price_per_month === '' || !pluginItem.price_per_month) {
          this.errorInfo.price_per_month = this.$t('pluginManagement.msgPricePerMonthRequired')
          check = false
        } else {
          this.errorInfo.price_per_month = ''
        }
      }
      return check
    }
  }
}
</script>
