<template>
  <Main>
    <template #body>
      <div class="row gutters">
        <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12">
          <div class="stats-tile2">
            <div class="sale-icon">
              <i class="icon-user1"></i>
            </div>
            <div class="sale-details">
              <h2>{{dataStatistic['number_customer'] ? dataStatistic['number_customer'] : ''}}</h2>
              <p>{{$t('statistic.customer')}}</p>
            </div>
          </div>
          <div class="stats-tile2">
            <div class="sale-icon">
              <i class="icon-box"></i>
            </div>
            <div class="sale-details">
              <h2>{{dataStatistic['number_domain'] ? dataStatistic['number_domain'] : ''}}</h2>
              <p>{{$t('statistic.domain')}}</p>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12">
          <div class="stats-tile2">
            <div class="sale-icon">
              <i class="icon-briefcase"></i>
            </div>
            <div class="sale-details">
              <h2>{{dataStatistic['number_plugin'] ? dataStatistic['number_plugin'] : ''}}</h2>
              <p>{{$t('statistic.plugin')}}</p>
            </div>
          </div>
          <div class="stats-tile2">
            <div class="sale-icon">
              <i class="icon-tonality"></i>
            </div>
            <div class="sale-details">
              <h2>{{dataStatistic['number_register_info'] ? dataStatistic['number_register_info'] : ''}}</h2>
              <p>{{$t('statistic.registerInfo')}}</p>
            </div>
          </div>
        </div>
        <div class="col-xl-8 col-lg-6 col-md-12 col-sm-12 col-12">
          <div class="card" style="height: 346px">
            <div class="card-header">
              <div class="card-title">Targets</div>
              <div class="graph-day-selection" role="group">
                <button type="button" class="btn active">Today</button>
                <button type="button" class="btn">Weekly</button>
                <button type="button" class="btn">Monthly</button>
              </div>
            </div>
            <div class="card-body">
              <div id="targets"></div>
            </div>
          </div>

        </div>
        <div v-for="i in 3" :key="i" class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="card h-350">
            <div class="card-header">
              <div class="card-title">By Channel</div>
            </div>
            <div class="card-body">
              <div id="byChannel"></div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Main>
</template>

<script>
import Main from '@/components/partials/layout/Main'
export default {
  name: 'home',
  components: {
    Main
  },
  data () {
    return {
      dataStatistic: {
      }
    }
  },

  head: {
    title: function () {
      return {
        inner: this.$t('menu.dashboard')
      }
    }
  },
  created () {
    this.handleGetDataTotalRecord()
  },
  methods: {
    handleGetDataTotalRecord () {
      let successCallback = (response) => {
        let { data } = response.data
        this.dataStatistic = data
      }
      let errorCallback = () => {}

      let payload = {
        successCallback,
        errorCallback
      }
      this.$store.dispatch('getTotalRecordStatistic', payload)
    }
  }
}
</script>
