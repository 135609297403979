import { CHANGE_STATUS_PLUGIN, CREATE_PLUGIN, DELETE_PLUGIN, GET_ALL_PLUGIN, GET_LIST_PLUGIN, GET_PLUGIN_BY_ID, UPDATE_PLUGIN } from '@/api/admin/plugin'

export default {

  getListPlugin ({ commit }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    GET_LIST_PLUGIN({ payload }, successCallback, errorCallback)
  },

  getPluginById ({ commit }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    GET_PLUGIN_BY_ID({ payload }, successCallback, errorCallback)
  },

  createPlugin ({ commit }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    CREATE_PLUGIN({ payload }, successCallback, errorCallback)
  },

  updatePlugin ({ commit }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    UPDATE_PLUGIN({ payload }, successCallback, errorCallback)
  },

  deletePlugin ({ commit }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    DELETE_PLUGIN({ payload }, successCallback, errorCallback)
  },

  changeStatusPlugin ({ commit }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    CHANGE_STATUS_PLUGIN({ payload }, successCallback, errorCallback)
  },

  getAllListPlugin ({ commit }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    GET_ALL_PLUGIN({ payload }, successCallback, errorCallback)
  }
}
