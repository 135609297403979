<template>
  <div class="custom-select">
    <el-select
        :allow-create="allowCreate"
        :default-first-option="allowCreate"
        :clearable="clearable"
        :disabled="disabled"
        v-bind="mergedProps"
        v-on="listeners"
        filterable
        :collapse-tags="collapseTags"
        :class="isFocused? `is-active ${classes}`:`${classes}`"
        :no-data-text="noDataText"
        :placeholder="placeholder"
        :multiple="isMulti">
      <slot name="options"></slot>
    </el-select>
  </div>
</template>
<script>
export default {
  data () {
    return {
      selected: null,
      isFocused: false
    }
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    classes: {
      type: String,
      required: false
    },
    isMulti: {
      type: Boolean,
      required: false,
      default: false
    },
    allowCreate: {
      type: Boolean,
      required: false,
      default: false
    },
    disableItem: {
      type: Boolean,
      required: false,
      default: false
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true
    },
    isSelectBlack: {
      type: Boolean,
      required: false,
      default: false
    },
    valueItem: {
      type: String,
      required: false,
      default: 'id'
    },
    textItem: {
      type: String,
      required: false,
      default: 'name'
    },
    collapseTags: {
      type: Boolean,
      default () {
        return false
      },
      required: false
    },
    noDataText: {
      type: String,
      required: false
    },
    value: {
      default: function () {
        return null
      }
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    listeners () {
      return {
        ...this.$listeners,
        blur: this.onBlur,
        focus: this.onFocus
      }
    },
    mergedProps () {
      return {
        ...this.$attrs,
        ...this.$props
      }
    }
  },
  methods: {
    onBlur () {
      this.isFocused = false
    },
    onFocus () {
      this.isFocused = true
    }
  }
}
</script>
<style lang="scss">
.custom-select{
  .el-select{
    border-radius: 4px;
    opacity: 1;
    input{
      border-radius: 2px;
      height: 45px;
      font-size: 16px;
      border:solid 1px #e3e6ea !important;
      @include Responsive(MD){
        height: 46px;
        font-size: 14px;
      }

    }
    .el-input__icon{
      @include Responsive(MD){
        line-height: 46px;
      }
    }
    &__tags{
      input{
        border: none  !important;
      }
    }
    .el-input__inner[disabled] {
      background-color: rgba(0,43,105,.1) !important;
      border: 2px solid  #a9acb3;
    }
    .el-tag{
      background: rgb(66, 114, 184);
      height: 30px;
      line-height: 30px;
      color:$white-color;
      @include regular-font(.7rem);
      opacity: 1;
      margin-right:10px !important;
      i{
        color:$black-color;
        opacity: 1;
        background-color: $white-color;
        margin-right:5px;
      }
    }
  }
  .is-active{
    opacity: 1 !important;
  }
  .select-small{
    min-width: 13rem !important;
    @include Responsive(MD){
      min-width: 10rem !important;
    }
    @include Responsive(LG){
      min-width: 10rem !important;
    }
    @include Responsive(XL){
      min-width: 14rem !important;
    }
    input{
      height: 40px !important;
      font-size: 14px;
    }
  }
  .select-all{
    min-width: 0px !important;
    .el-select__tags{
      flex-wrap: nowrap;
      input {
        padding: 0px !important;
      }
    }

  }
  .small-height{
    min-width: 120px;
    &__max{
      input{
        min-width: 350px !important;
        &.el-select__input{
          min-width: 20px !important;
        }
      }
    }
    input{
      height: 40px !important;
      font-size: 14px;
      min-width: 250px;
      &.el-select__input{
        min-width: 20px;
      }
    }
    .el-input__icon{
      line-height: 40px;
    }
  }
}
</style>
