<template>
  <div class="non-siderbar">
    <div class="login-container">
      <div class="container-fluid h-100">
        <div class="row g-0 h-100">
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="login-wrapper">
              <form>
                <div class="login-screen">
                  <div class="login-body mb-5">
                    <a href="/" class="login-logo">
                      <img src="@/assets/images/app_icon_default.png" alt="admin-plugin-logo">
                      <span>Plugin Admin</span>
                    </a>
                    <h6>Welcome back,<br>Please login to your account.</h6>
                    <div class="field-wrapper">
                      <input
                          v-model="secureOtp"
                          type="email"
                          class="form-control"
                          :class="{'form-error':errorInfo.secureOtp}" >
                      <div class="field-placeholder">
                        {{$t('admin.form.secureCode')}}
                        <span class="text-danger">*</span>
                      </div>
                      <div
                          class="invalid-feedback"
                          :class="{'d-block': errorInfo.secureOtp }"
                          v-if="errorInfo.secureOtp">
                        {{errorInfo.secureOtp}}
                      </div>
                    </div>
                    <div class="field-wrapper mb-3">
                      <el-input
                          :class="{'form-error-login':errorInfo.password}"
                          v-model="password"
                          show-password
                          type="password">
                      </el-input>
                      <div class="field-placeholder">
                        {{$t('pageLogin.password')}}
                        <span class="text-danger">*</span>
                      </div>
                      <div
                          class="invalid-feedback"
                          :class="{'d-block': errorInfo.password }"
                          v-if="errorInfo.password">
                        {{errorInfo.password}}
                      </div>
                    </div>
                    <div class="field-wrapper">
                      <input
                          v-model="passwordConfirm"
                          type="email"
                          class="form-control"
                          :class="{'form-error':errorInfo.passwordConfirm}" >
                      <div class="field-placeholder">
                        {{$t('admin.form.labelPasswordConfirm')}}
                        <span class="text-danger">*</span>
                      </div>
                      <div
                          class="invalid-feedback"
                          :class="{'d-block': errorInfo.passwordConfirm }"
                          v-if="errorInfo.passwordConfirm">
                        {{errorInfo.passwordConfirm}}
                      </div>
                    </div>
                    <div class="actions">
                      <a href="javascript:void(0)" @click="path('/login')">{{$t('admin.login.backToLogin')}}</a>
                      <button type="button" @click="login" class="btn btn-primary">{{$t('button.save')}}</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'login',
  head: {
    title: function () {
      return {
        inner: this.$t('menu.resetPassword')
      }
    }
  },
  data () {
    return {
      secureOtp: '',
      password: '',
      passwordConfirm: '',
      isLoading: false,
      errorInfo: {
        secureOtp: '',
        password: '',
        passwordConfirm: ''
      },
      isShowPassword: false,
      isShowPasswordConfirm: false
    }
  },
  methods: {
    enterKeyboard: function (e) {
      if (e.keyCode === 13) {
        this.login()
      }
    },
    path (path) {
      this.$router.push({ path: path })
    },
    login () {
      let check = this.validate()
      if (check) {
        this.isLoading = true
        let successCallback = (response) => {
          this.$message({
            showClose: true,
            message: this.$t('admin.forgot.changePasswordSuccess'),
            type: 'success'
          })
          setTimeout(() => {
            this.$router.push({ path: '/login' })
            this.isLoading = false
          }, 1000)
        }
        let errorCallback = () => {
          this.$message({
            showClose: true,
            message: this.$t('admin.form.optValidFail'),
            type: 'error'
          })
          this.isLoading = false
        }
        let payload = {
          data: {
            otp: this.secureOtp,
            password: this.password
          },
          successCallback,
          errorCallback
        }
        this.$store.dispatch('auth/resetPassword', payload)
      }
    },
    validate (field) {
      let check = true
      if ((field === 'secureOtp' || !field)) {
        if (this.secureOtp === '' || !this.secureOtp) {
          this.errorInfo.secureOtp = this.$t('admin.form.emptySecureOtp')
          check = false
        } else {
          if (this.secureOtp.length < 6 || this.secureOtp.length > 8) {
            this.errorInfo.secureOtp = this.$t('admin.form.optLengthValid')
            check = false
          } else {
            this.errorInfo.secureOtp = ''
          }
        }
      }
      if (field === 'password' || !field) {
        if (this.password === '' || !this.password) {
          this.errorInfo.password = this.$t('admin.form.emptyNewPassword')
          check = false
        } else if (this.password && (this.password.length < 8 || this.password.length > 32)) {
          this.errorInfo.password = this.$t('admin.form.errorPasswordFormat')
          check = false
        } else {
          this.errorInfo.password = ''
        }
      }
      if ((field === 'passwordConfirm' || !field)) {
        if ((this.password && this.password.length >= 8 && this.password.length <= 32) || this.passwordConfirm === '') {
          if (this.passwordConfirm === '' || !this.passwordConfirm) {
            this.errorInfo.passwordConfirm = this.$t('admin.form.emptyPasswordConfirm')
            check = false
          } else if (this.password !== this.passwordConfirm) {
            this.errorInfo.passwordConfirm = this.$t('admin.form.passwordNotMatch')
            check = false
          } else {
            this.errorInfo.passwordConfirm = ''
          }
        } else if (this.passwordConfirm) {
          this.errorInfo.passwordConfirm = ''
        }
      }
      return check
    }
  }
}
</script>
