'use strict'
import Api from '@/api/abstract'
import { APP_TOKEN_NAME } from '../../config/constants'

export let GET_ALL_TOTAL_RECORD = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/master/all-total-record`
  let headers = {
    Authorization: `${window.$cookies.get(APP_TOKEN_NAME)}`
  }
  return new Api(url, 'get', successCallback, errorCallback, { headers })
}
