<template>
<Main>
  <template #body>
    <Card>
      <template #header>
        {{ isEdit ? $t('menu.customer.edit') : $t('menu.customer.add')}}
      </template>
      <template #body>
        <form action="">
          <el-divider content-position="left">
            <h6 class="font-weight-bold">{{$t('customerManagement.infoBasic')}}</h6>
          </el-divider>
          <el-row>
            <el-col :span="16" :xs="24">
              <el-row :gutter="20">
                <el-col :span="12" :xs="24" :lg="12">
                  <InputField classes="mb-3">
                    <template #label>{{$t('customerManagement.code')}}</template>
                    <template #input>
                      <InputText
                          readonly ref="customerCode"
                          v-model="customerItem.code">
                      </InputText>
                    </template>
                  </InputField>
                </el-col>
                <el-col :span="12" :xs="24" :lg="12">
                  <InputField>
                    <template #label>
                      {{$t('customerManagement.email')}}
                      <span class="text-danger"> *</span>
                    </template>
                    <template #input>
                      <InputText
                          v-model="customerItem.email"
                          @keydown="validate('email')"
                          @keyup="validate('email')">
                      </InputText>
                    </template>
                    <template #error v-if="errorInfo.email">
                      {{errorInfo.email}}
                    </template>
                  </InputField>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12" :xs="24" :lg="12">
                  <InputField classes="mb-3">
                    <template #label>
                      {{$t('customerManagement.name')}}
                      <span class="text-danger"> *</span>
                    </template>
                    <template #input>
                      <InputText
                          v-model="customerItem.full_name"
                          @keydown="validate('full_name')"
                          @keyup="validate('full_name')">
                      </InputText>
                    </template>
                    <template #error v-if="errorInfo.full_name">
                      {{errorInfo.full_name}}
                    </template>
                  </InputField>
                </el-col>
                <el-col :span="12" :xs="24" :lg="12">
                  <InputField classes="mb-3" >
                    <template #label>{{$t('customerManagement.address')}}</template>
                    <template #input>
                      <InputText v-model="customerItem.address" ></InputText>
                    </template>
                  </InputField>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12" :xs="24" :lg="12">
                  <InputField classes="mb-3">
                    <template #label>
                      {{$t('customerManagement.phone')}}
                      <span class="text-danger"> *</span>
                    </template>
                    <template #input>
                      <InputText
                          @keypress="onValidPhoneNumber"
                          v-model="customerItem.phone"
                          @keyup="validate('phone')"
                          @keydown="validate('phone')">
                      </InputText>
                    </template>
                    <template #error v-if="errorInfo.phone">
                      {{errorInfo.phone}}
                    </template>
                  </InputField>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="8" :xs="24">
              <div class="w-100 text-center">
                <FormUpload v-model="customerItem.avatar" />
                <div>Avatar</div>
              </div>
            </el-col>
          </el-row>
          <el-divider content-position="left">
            <h6 class="font-weight-bold">{{$t('customerManagement.termContract')}}</h6>
          </el-divider>
          <el-row :gutter="20">
            <el-col :span="8" :xl="8" :lg="8" :xs="24">
              <InputField>
                <template #label>{{$t('customerManagement.status')}}</template>
                <template #input>
                  <CustomSelect v-model="customerItem.status">
                    <template #options>
                      <el-option
                          v-for="item in optionsStatus"
                          :key="item.value"
                          :value="item.value"
                          :label="$t(item.text)">
                      </el-option>
                    </template>
                  </CustomSelect>
                </template>
              </InputField>
            </el-col>
            <el-col :span="16">
              <InputField>
                <template #label>
                  {{$t('customerManagement.note')}}
                </template>
                <template #input>
                  <el-input v-model="customerItem.note" :rows="5"  type="textarea"></el-input>
                </template>
              </InputField>
            </el-col>
          </el-row>
          <el-divider content-position="left">
            <h6 class="font-weight-bold">{{$t('customerManagement.listDomain')}}</h6>
          </el-divider>
          <el-row :gutter="20" >
            <el-col :span="24">
              <Button type="primary" @click="onAddDomain" classes="mb-4">
                <i class="el-icon-plus"></i>{{$t('button.add')}}
              </Button>
              <div class="table-responsive tableItems">
                <table class="table custom-table">
                  <thead>
                  <tr>
                    <th>Domain</th>
                    <th>Name</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(domain,index) in customerItem.listDomain" :key="index+1">
                    <td class="w-40">
                      <InputField classes="my-0">
                        <template #input>
                          <InputText v-model="domain.domain" classes="w-100" placeholder="URL kintone"></InputText>
                        </template>
                        <template #error v-if="domain.error">{{domain.error}}</template>
                      </InputField>
                    </td>
                    <td class="w-40">
                      <InputField classes="my-0">
                        <template #input>
                          <InputText v-model="domain.name_app" classes="w-100" placeholder="Name"></InputText>
                        </template>
                      </InputField>
                    </td>
                    <td>
                      <i
                          @click="onDeleteDomain(index, domain)"
                          class="el-icon-circle-close btn-delete"
                          :class="{'item-disabled':domain['RegisterInfos'] && domain['RegisterInfos'].length > 0}"
                          :title="$t('button.delete')"></i>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </el-col>
          </el-row>
          <div class="text-right pr-3">
            <Button
                :disabled="isLoadingDelete"
                v-loading="isLoadingDelete"
                class="btn btn-danger mr-3"
                v-if="isEdit && !isManyBill"
                type="cancel"
                @click="onDeleteRecord(customerItem)">
              {{$t('button.delete')}}
            </Button>
            <Button
                class="btn btn-outline-primary mr-3"
                type="cancel"
                @click="cancel">
              {{$t('button.cancel')}}
            </Button>
            <Button
                :disabled="isLoading"
                v-loading="isLoading"
                class="btn btn-primary"
                type="submit"
                @click="createCustomer">
              {{isEdit? $t('button.save') :$t('button.register')}}
            </Button>
          </div>
        </form>
      </template>
    </Card>
  </template>
</Main>
</template>
<script>
import Main from '@/components/partials/layout/Main'
import Card from '@/components/partials/Card'
import InputField from '@/components/partials/InputField'
import InputText from '@/components/partials/forms/InputText'
import CustomSelect from '@/components/partials/forms/CustomSelect'
import { mapState } from 'vuex'
import { validEmail, formatNumberJapan } from '@/utils'
import { LIST_STATUS_CUSTOMER } from '@/config/constants'
import Button from '@/components/partials/Button'
import FormUpload from '@/components/partials/forms/FormUpload'
export default {
  components: {
    Main, Card, InputField, InputText, CustomSelect, Button, FormUpload
  },
  data () {
    return {
      isLoading: false,
      isLoadingDelete: false,
      listPlan: [],
      isEdit: false,
      isManyBill: false,
      customerItem: {
        listDomain: [],
        code: null,
        address: null,
        birthDay: null,
        userId: null,
        avatar: '',
        name: null,
        phone: null,
        email: null,
        password: null,
        status: 1,
        note: null
      },
      errorInfo: {
        password: '',
        email: '',
        phone: '',
        name: '',
        plan: '',
        termDay: '',
        listDomain: ''
      },
      pickerOptions: {
        disabledDate: this.disabledDate,
        onPick: this.pick
      },
      toDate: new Date()
    }
  },
  head: {
    title: function () {
      return {
        inner: this.$route.query.id ? this.$t('menu.customer.edit') : this.$t('menu.customer.add')
      }
    }
  },

  created () {
    if (this.$route.query.id) {
      this.isEdit = true
      this.onGetCustomerById(this.$route.query.id)
    }
    this.$store.commit('setBreadCrumb', [
      { label: 'customerManagement', link: 'customerManagement' },
      { label: this.$route.query.id ? 'customer.edit' : 'customer.add' }
    ])
  },
  computed: {
    ...mapState('auth', ['userInfo']),
    optionsStatus () {
      return LIST_STATUS_CUSTOMER
    }
  },
  methods: {
    formatNumber (val) {
      if (val) {
        if (Number(val) % 1 === 0) {
          return val
        } else {
          return formatNumberJapan(Number(val), 2)
        }
      } else {
        return 0
      }
    },
    onValidPhoneNumber (evt) {
      evt = (evt) || window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    cancel () {
      this.$router.push({ name: 'customerManagement' })
    },
    showErrorNotExisted (statusCode) {
      if (statusCode === 404) {
        this.$message({
          showClose: true,
          message: this.$t('customerManagement.customerDeletedError'),
          type: 'error'
        })
      }
      if (statusCode === 422) {
        this.$message({
          showClose: true,
          message: this.$t('customerManagement.userRegistered'),
          type: 'error'
        })
      }
      this.cancel()
    },
    showErrorCreated (bug) {
      let message = ''
      switch (bug) {
        case 'package not found':
          message = this.$t('customerManagement.msgPlanDeleted')
          break
        case 'package is inactive':
          message = this.$t('customerManagement.msgPlanDeActive')
          break
        case 'Email already exists':
          message = this.$t('customerManagement.userRegistered')
          break
        case 'user_not_found':
          message = this.$t('customerManagement.customerDeletedError')
          break
        default:
          message = bug
      }
      this.$message({
        showClose: true,
        message: message,
        type: 'error'
      })
    },
    onGetCustomerById (idCustomer) {
      let successCallback = (response) => {
        if (response.status === 200) {
          let { data } = response.data
          this.customerItem = data
          this.customerItem.listDomain = data.listDomain || []
          this.isManyBill = data.listDomain.filter(elm => elm['RegisterInfos'] && elm['RegisterInfos'].length > 0).length > 0
        }
      }
      let errorCallback = (error) => {
        if (error.response) {
          let statusCode = error.response.data.code
          this.showErrorNotExisted(statusCode)
        }
      }
      let payload = {
        successCallback,
        errorCallback,
        id: idCustomer
      }
      this.$store.dispatch('customer/getCustomerById', payload)
    },

    onDeleteRecord (item) {
      this.$confirm(this.$t('customerManagement.msgConfirmDelete'), this.$t('msg.titleSuccess'), {
        confirmButtonText: this.$t('button.ok'),
        cancelButtonText: this.$t('button.cancel'),
        type: 'warning'
      }).then(() => {
        this.isLoadingDelete = true
        let successCallback = (response) => {
          if (response.status === 200) {
            this.$message({
              showClose: true,
              message: this.$t('customerManagement.msgDeleteSuccess'),
              type: 'success'
            })
            setTimeout(() => {
              this.isLoadingDelete = false
              this.cancel()
            }, 500)
          }
        }
        let errorCallback = (error) => {
          this.isLoadingDelete = false
          if (error.response) {
            let statusCode = error.response.data.code
            this.showErrorNotExisted(statusCode)
          }
        }
        let payload = {
          id: item.userId,
          successCallback,
          errorCallback
        }
        this.$store.dispatch('customer/deleteCustomer', payload)
      }).catch(() => {
        this.isLoadingDelete = false
      })
    },
    handleCheckUniqueDomain () {
      let result = true
      let { listDomain } = this.customerItem
      this._.map(listDomain, (elm) => {
        if (elm && elm.domain) {
          let indexFirst = this._.findIndex(listDomain, record => record.domain === elm.domain)
          let indexLast = this._.findLastIndex(listDomain, record => record.domain === elm.domain)
          if (indexFirst !== indexLast && listDomain.length > 1) {
            elm.error = 'Domain Duplicate'
            result = false
          } else {
            elm.error = ''
          }
        } else {
          elm.error = 'Domain Required'
        }
      })
      this.customerItem.listDomain = [...listDomain]
      return result
    },
    createCustomer () {
      let check = this.validate()
      if (check) {
        this.isLoading = true
        let successCallback = (response) => {
          if (response.status === 200) {
            this.isLoading = false
            this.$message({
              showClose: true,
              message: this.isEdit ? this.$t('customerManagement.msgAddSuccess') : this.$t('customerManagement.msgUpdateSuccess'),
              type: 'success'
            })
            this.$router.push({ name: 'customerManagement' })
          }
        }
        let errorCallback = (error) => {
          this.isLoading = false
          if (error.response) {
            let messageBug = error.response.data.message ? error.response.data.message : this.$t('textCommon.msgEmailExisted')
            this.showErrorCreated(messageBug)
          }
        }
        let data = Object.assign({}, this.customerItem)
        data.domainsRegister = [].concat(this.customerItem.listDomain)
        delete data.listDomain
        let payload = {
          successCallback,
          errorCallback,
          data: data,
          id: this.isEdit ? this.$route.query.id : null
        }
        let action = this.isEdit ? 'customer/updateCustomer' : 'customer/createCustomer'
        this.$store.dispatch(action, payload)
      }
    },
    validate (field) {
      let isDupsDomain = this.handleCheckUniqueDomain()
      let check = true
      if (!isDupsDomain) {
        check = false
      }
      let { customerItem } = this
      if ((field === 'email' || !field)) {
        if (customerItem.email === '' || !customerItem.email) {
          this.errorInfo.email = this.$t('textCommon.msgEmailRequired')
          check = false
        } else if (customerItem.email && !validEmail(customerItem.email)) {
          this.errorInfo.email = this.$t('textCommon.msgEmailValid')
          check = false
        } else {
          this.errorInfo.email = ''
        }
      }
      if (field === 'full_name' || !field) {
        if (customerItem.full_name === '' || !customerItem.full_name) {
          this.errorInfo.full_name = this.$t('customerManagement.msgNameRequired')
          check = false
        } else {
          this.errorInfo.full_name = ''
        }
      }
      if (field === 'phone' || !field) {
        if (customerItem.phone === '' || !customerItem.phone) {
          this.errorInfo.phone = this.$t('customerManagement.msgPhoneRequired')
          check = false
        } else {
          if (customerItem.phone.length === 10 || customerItem.phone.length === 11) {
            this.errorInfo.phone = ''
          } else {
            this.errorInfo.phone = this.$t('textCommon.msgPhoneValid')
            check = false
          }
        }
      }
      return check
    },
    onAddDomain () {
      const { listDomain } = this.customerItem
      let newItem = {
        id: null,
        domain: '',
        name_app: ''
      }
      this.customerItem.listDomain = [...listDomain, newItem]
    },
    onDeleteDomain (index, domain) {
      if (domain['RegisterInfos'] && domain['RegisterInfos'].length > 0) {
        return false
      } else {
        this.customerItem.listDomain.splice(index, 1)
      }
    },
    pick ({ maxDate, minDate }) {
      this.toDate = maxDate
    },
    disabledDate (date) {
      if (this.toDate) {
        return this.toDate < date
      }
      return false
    }
  }
}
</script>
