'use strict'
import Api from '@/api/abstract'
import { APP_TOKEN_NAME } from '../../config/constants'

export let GET_LIST_PLUGIN = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/plugin?${params.payload.query}`
  let headers = {
    Authorization: `${window.$cookies.get(APP_TOKEN_NAME)}`
  }
  return new Api(url, 'get', successCallback, errorCallback, { headers })
}

export let GET_ALL_PLUGIN = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/master/all-plugin`
  let headers = {
    Authorization: `${window.$cookies.get(APP_TOKEN_NAME)}`
  }
  return new Api(url, 'get', successCallback, errorCallback, { headers })
}

export let GET_PLUGIN_BY_ID = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/plugin/${params.payload.id}`
  let headers = {
    Authorization: `${window.$cookies.get(APP_TOKEN_NAME)}`
  }
  return new Api(url, 'get', successCallback, errorCallback, { headers })
}

export let CREATE_PLUGIN = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/plugin`
  let headers = {
    Authorization: `${window.$cookies.get(APP_TOKEN_NAME)}`
  }
  let data = params.payload.data
  return new Api(url, 'post', successCallback, errorCallback, { headers, data })
}

export let UPDATE_PLUGIN = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/plugin/${params.payload.data.id}`
  let headers = {
    Authorization: `${window.$cookies.get(APP_TOKEN_NAME)}`
  }
  let data = params.payload.data
  return new Api(url, 'put', successCallback, errorCallback, { headers, data })
}

export let DELETE_PLUGIN = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/plugin/${params.payload.id}`
  let headers = {
    Authorization: `${window.$cookies.get(APP_TOKEN_NAME)}`
  }
  return new Api(url, 'delete', successCallback, errorCallback, { headers })
}

export let CHANGE_STATUS_PLUGIN = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/plugin/${params.payload.id}`
  let headers = {
    Authorization: `${window.$cookies.get(APP_TOKEN_NAME)}`
  }
  return new Api(url, 'patch', successCallback, errorCallback, { headers })
}
