import ListRegisterInfo from '@/views/registerPlugin/index'
import EditRegisterInfo from '@/views/registerPlugin/edit'
import { APP_TOKEN_NAME } from '@/config/constants'
const ifAuthenticated = (to, from, next) => {
  let token = window.$cookies.get(APP_TOKEN_NAME)
  if (to.path !== '/login' && token) {
    next()
  } else {
    next({ name: 'login' })
  }
}
export default [
  {
    path: '/register-plugin',
    name: 'registerPlugins',
    component: ListRegisterInfo,
    meta: {
      permission: []
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/register-plugin/edit',
    name: 'editRegisterInfo',
    component: EditRegisterInfo,
    meta: {
      permission: []
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/register-plugin/add',
    name: 'addRegisterInfo',
    component: EditRegisterInfo,
    meta: {
      permission: []
    },
    beforeEnter: ifAuthenticated
  }
]
