<template>
  <div id="app" >
    <router-view :key="$route.fullPath"/>
  </div>
</template>

<script>
import { APP_TOKEN_NAME } from './config/constants'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      verified: false
    }
  },
  computed: {
    ...mapState('auth', ['userInfo']),
    ...mapState(['codeError']),
    currentPage () {
      return this.$route.name
    }
  },
  watch: {
    codeError (val) {
      if (val) {
        let message = ''
        switch (val) {
          case 416:
            message = this.$t('msg.error416')
            break
          case 415:
            message = this.$t('msg.tokenExpired')
            break
          case 515:
            message = this.$t('msg.NetWorkError')
            break
          default:
            message = this.$t('msg.tokenExpired')
        }
        this.$message({
          showClose: true,
          message: message,
          type: 'error'
        })
        setTimeout(() => {
          this.$store.commit('SET_CODE_ERROR', null)
        }, 2500)
      }
    }
  },
  created () {
    let token = this.$cookies.get(APP_TOKEN_NAME)
    if (token) {

    } else {
      this.verified = true
      if (this.$route.path !== '/forgot-password' && this.$route.path !== '/reset-password') {
        this.$router.push({ path: '/login' })
      }
    }
  },
  methods: {
    getInfoUser () {
      let successCallback = (response) => {
        if (response.status === 200) {
          let dataInfo = response.data.data
          this.$store.commit('auth/auth_success', dataInfo)
        }
      }
      let errorCallback = () => {}

      let payload = {
        successCallback,
        errorCallback
      }
      this.$store.dispatch('auth/getMyInfo', payload)
    }
  }
}

</script>

<style lang="scss">
  .v-loading-refresh{
    height: 100vh;
    position: fixed;
    top: 0;
    width: 100%;
  }
  .dashboard-login .header {
    position: relative;
    background-color: transparent;
  }
</style>
