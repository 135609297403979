import uniqid from 'uniqid'
import { APP_TOKEN_NAME } from '../config/constants'

export default {
  openNotification (state, payload) {
    state.notificationData = Object.assign({}, {
      show: true,
      message: payload.message,
      type: payload.type
    })
  },
  openConfirm (state, payload) {
    state.confirm = Object.assign({}, {
      show: true,
      message: payload.message,
      title: payload.title,
      callback: payload.callback
    })
  },
  closeConfirm (state) {
    state.confirm = Object.assign({}, {
      show: false,
      message: '',
      title: '',
      callback: null
    })
  },

  increaseLoading (state) {
    state.loading.count++
  },
  decreaseLoading (state) {
    state.loading.count--
  },

  addAlertItem (state, alert) {
    alert.id = uniqid()
    state.alertList.push(alert)
  },
  setSocket (state, data) {
    state.socket = data
  },
  setFirebaseToken (state, data) {
    let token = window.$cookies.get(APP_TOKEN_NAME)
    if (token) {
      let successCallback = (response) => {
      }
      let errorCallback = () => {
      }
      let payload = {
        data: {
          os: 'Web',
          device_token: data
        },
        successCallback,
        errorCallback
      }
      this.dispatch('user/refreshFirebaseToken', payload)
    }
    state.firebaseToken = data
  },
  setBreadCrumb (state, data) {
    state.breadCrumb = data
  },
  SET_CODE_ERROR (state, payload) {
    state.codeError = payload
  },
  SET_REFRESH_TOKEN (state, payload) {
    state.refreshToken = payload
  },
  SHOW_MODAL (state, payload) {
    state.isShowModalMap = payload
  },

  SET_CURRENT_MENU (state, menu) {
    state.currentMenu = menu
  },
  SET_CURRENT_PATH (state, path) {
    state.currentPath = path
  }
}
