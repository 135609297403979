'use strict'
import Api from '@/api/abstract'
import { APP_TOKEN_NAME } from '../../config/constants'

export let GET_LIST_STAFF = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/staff?${params.payload.query}`
  let headers = {
    Authorization: `Bearer ${window.$cookies.get(APP_TOKEN_NAME)}`
  }
  return new Api(url, 'get', successCallback, errorCallback, { headers })
}

export let GET_ALL_STAFF = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/staff?page=1&limit=1000`
  let headers = {
    Authorization: `Bearer ${window.$cookies.get(APP_TOKEN_NAME)}`
  }
  return new Api(url, 'get', successCallback, errorCallback, { headers })
}

export let GET_STAFF_BY_ID = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/staff/${params.payload.id}`
  let headers = {
    Authorization: `Bearer ${window.$cookies.get(APP_TOKEN_NAME)}`
  }
  return new Api(url, 'get', successCallback, errorCallback, { headers })
}

export let CREATE_STAFF = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/staff`
  let headers = {
    Authorization: `Bearer ${window.$cookies.get(APP_TOKEN_NAME)}`
  }
  let data = params.payload.data
  return new Api(url, 'post', successCallback, errorCallback, { headers, data })
}

export let UPDATE_STAFF = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/staff/${params.payload.data.id}`
  let headers = {
    Authorization: `Bearer ${window.$cookies.get(APP_TOKEN_NAME)}`
  }
  let data = params.payload.data
  return new Api(url, 'put', successCallback, errorCallback, { headers, data })
}

export let DELETE_STAFF = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/staff/${params.payload.id}`
  let headers = {
    Authorization: `Bearer ${window.$cookies.get(APP_TOKEN_NAME)}`
  }
  return new Api(url, 'delete', successCallback, errorCallback, { headers })
}

export let CHANGE_STATUS_STAFF = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/staff/${params.payload.id}`
  let headers = {
    Authorization: `Bearer ${window.$cookies.get(APP_TOKEN_NAME)}`
  }
  return new Api(url, 'patch', successCallback, errorCallback, { headers })
}
