<template>
  <div class="input-field-number">
    {{formatNumberR(number)}}
  </div>
</template>

<script>
import {
  addComasNumber
} from '@/utils'

export default {
  name: 'FormatNumber',
  props: {
    number: {
      require: true
    },
    error: {
      require: false
    },
    placeholder: {
      require: false
    },
    decimal: {
      require: false,
      default: false
    }
  },
  methods: {
    formatNumberR (n) {
      return addComasNumber(n)
    }
  }
}
</script>

<style lang="scss">
.input-field-number {
  display: inline-block;
}
</style>
