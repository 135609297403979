
const MENUS = [
  {
    icon: 'icon-home2',
    label: 'dashboard',
    path: 'dashboard',
    meta: {
      permission: []
    },
    pathActive: [
      'dashboard',
      'profile'
    ],
    subMenu: [
      {
        icon: '',
        field: 'menu.dashboard',
        taxPropertyManagement: 'common.titlePageUpdate',
        label: 'dashboard',
        path: 'dashboard',
        meta: {
          permission: []
        },
        pathActive: [
          'dashboard',
          'profile'
        ],
        subMenu: []
      }
    ]
  },
  {
    icon: 'icon-person',
    label: 'customerManagement',
    path: 'customerManagement',
    meta: {
      permission: []
    },
    pathActive: [
      'customerManagement',
      'editCustomer',
      'addCustomer',
      'detailCustomer'
    ],
    subMenu: [
      {
        icon: '',
        field: 'pageTaxProperty.field',
        taxPropertyManagement: 'common.titlePageUpdate',
        label: 'customer.list',
        path: 'customerManagement',
        meta: {
          permission: []
        },
        pathActive: [
          'customerManagement',
          'editCustomer'
        ],
        subMenu: []
      },
      {
        icon: '',
        field: 'pageCustomerProperty.field',
        customerPropertyManagement: 'common.titlePageManage',
        label: 'customer.add',
        path: 'addCustomer',
        meta: {
          permission: []
        },
        pathActive: [
          'addCustomer'
        ],
        subMenu: []
      }
    ]
  },
  {
    icon: 'el-icon-set-up',
    label: 'pluginManagement',
    path: 'pluginsManagement',
    meta: {
      permission: []
    },
    pathActive: [
      'pluginsManagement',
      'addPlugin',
      'editPlugin',
      'detailPlugin'
    ],
    subMenu: [
      {
        icon: '',
        label: 'plugin.list',
        field: 'pageSupplier.field',
        path: 'pluginsManagement',
        meta: {
          permission: []
        },
        pathActive: [
          'pluginsManagement',
          'editPlugin'
        ],
        subMenu: []
      },
      {
        icon: '',
        label: 'plugin.add',
        field: 'pageSupplier.field',
        path: 'addPlugin',
        meta: {
          permission: []
        },
        pathActive: [
          'addPlugin'
        ],
        subMenu: []
      }
    ]
  },
  {
    icon: 'el-icon-s-order',
    label: 'registerPlugin',
    path: 'registerPlugins',
    meta: {
      permission: []
    },
    pathActive: [
      'registerPlugins',
      'addRegisterInfo',
      'editRegisterInfo',
      'detailRegister'
    ],
    subMenu: [
      {
        icon: '',
        field: 'pageMaterialList.field',
        label: 'registerInfo.list',
        path: 'registerPlugins',
        meta: {
          permission: []
        },
        pathActive: [
          'registerPlugins',
          'editRegisterInfo',
          'detailRegister'
        ],
        subMenu: []
      },
      {
        icon: '',
        field: 'pageMaterialUnit.field',
        label: 'registerInfo.add',
        path: 'addRegisterInfo',
        meta: {
          permission: []
        },
        pathActive: [
          'addRegisterInfo'
        ],
        subMenu: []
      }
    ]
  }
]
export default MENUS
