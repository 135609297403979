'use strict'
import Api from '@/api/abstract'
import { APP_TOKEN_NAME } from '../../config/constants'
export let UPLOAD_FILE = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/commons/avatar`
  let headers = {
    Authorization: `${window.$cookies.get(APP_TOKEN_NAME)}`
  }
  let data = params.payload.data
  return new Api(url, 'post', successCallback, errorCallback, { headers, data })
}
