<template>
  <div class="format-date">
    {{convertDate()}}
  </div>
</template>

<script>
import {
  formatDateTime
} from '@/utils'

export default {
  name: 'FormatDate',
  props: {
    date: {
      require: true
    },
    format: String
  },
  model: {
    prop: 'date',
    event: 'update'
  },
  methods: {
    convertDate () {
      if (!this.date) return ''
      let date = new Date(this.date)
      return formatDateTime(this.format, date)
    }
  }
}
</script>
