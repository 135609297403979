import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

// Page common
import Dashboard from '../views/dashboard/index'
import NotFoundPage from '@/views/notFoundPage'
import Settings from '../views/settings/index'

// Module
import AdminAuth from './modules/auth.router'
import CustomerRouter from './modules/customer.router'
import PluginRouter from './modules/plugins.router'
import RegisterRouter from './modules/registerPlugin.router'
import { APP_TOKEN_NAME } from '@/config/constants'
import MENUS from '@/config/menuSiderBar'
const ifAuthenticated = (to, from, next) => {
  let token = window.$cookies.get(APP_TOKEN_NAME)
  if (to.path !== '/login' && token) {
    next()
  } else {
    next({ name: 'login' })
  }
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      permission: []
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: {
      permission: []
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '*',
    name: 'NotFound',
    meta: {},
    component: NotFoundPage
  },
  ...AdminAuth,
  ...CustomerRouter,
  ...PluginRouter,
  ...RegisterRouter

]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.afterEach((to, from, next) => {
  let currentPath = to.name
  let currentMenu
  for (let index in MENUS) {
    let menu = MENUS[index]

    if (Array.isArray(menu.pathList) && menu.pathList.indexOf(currentPath) !== -1) {
      currentMenu = menu
      break
    }
  }
  if (currentMenu === undefined) {
    currentMenu = null
  }
  store.commit('SET_CURRENT_MENU', currentMenu)
  store.commit('SET_CURRENT_PATH', currentPath)
})

export default router
