<template>
  <div class="page-wrapper" :style="styleObject">
    <SideBarPartial></SideBarPartial>
    <div class="main-container" :class="{'main-slim': !onCheckActive}">
      <div class="page-header">
        <div class="row gutters">
          <div class="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-9">
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-3">
            <ul class="header-actions">
              <li class="dropdown">
                <a href="#" id="tasks" data-toggle="dropdown" aria-haspopup="true">
                  <i class="el-icon-setting"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-end lrg" aria-labelledby="tasks">
                  <div class="dropdown-menu-header">
                    {{$t('menu.settings')}}
                  </div>
                  <div class="customScroll px-4 py-1">
                    <InputField label-top>
                      <template #label>
                        {{$t('chooseLanguage')}}
                      </template>
                      <template #input>
                        <div class="pl-3 mt-2">
                          <div
                              class="form-check mb-2"
                              v-for="item in listLanguage"
                              :key="item.key">
                            <input
                                class="form-check-input"
                                @change="setLanguage"
                                type="radio"
                                :value="item.key"
                                v-model="language"
                                :name="item.key"
                                :id="item.key">
                            <label class="form-check-label" :for="item.key">
                              {{item.label}}
                            </label>
                          </div>
                        </div>
                      </template>
                    </InputField>
                    <InputField label-top>
                      <template #label>
                        {{$t('changeBackground')}}
                      </template>
                      <template #input>
                        <div class="pl-3 mt-2">
                          <input type="color" v-model="color" @change="onSetBackground">
                        </div>
                      </template>
                    </InputField>
                  </div>
                </div>
              </li>
              <li class="dropdown">
                <a href="#" id="userSettings" class="user-settings" data-toggle="dropdown" aria-haspopup="true">
                  <img style="width: 40px; height: 40px; border-radius: 50%" src="@/assets/img/user.svg">
                </a>
                <div class="dropdown-menu dropdown-menu-end sm mt-1" aria-labelledby="userSettings">
                  <div class="header-profile-actions">
                    <a href="javascript:void(0)" @click="$router.push('/user-profile')"><i class="icon-user1"></i>Profile</a>
                    <a @click="onLogout" href="javascript:void(0)"><i class="icon-log-out1"></i>Logout</a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="content-wrapper-scroll">
        <div class="content-wrapper">
          <slot name="body"></slot>
          <Confirm/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBarPartial from './SideBar'
import InputField from '../InputField'
import { APP_TOKEN_NAME, LANGUAGE_SETTING_APP, LIST_LANGUAGE, USER_INFO_KEY } from '@/config/constants'
import moment from 'moment'
import { generateStorageKey } from '@/utils'
import MENUS from '@/config/menuSiderBar'
import Confirm from '@/components/partials/Confirm'
export default {
  name: 'mainPartial',
  props: {
    noPadding: {
      type: Boolean
    }
  },
  components: {
    Confirm,
    InputField,
    SideBarPartial
  },
  data () {
    return {
      language: null,
      MENUS: MENUS,
      listLanguage: LIST_LANGUAGE,
      color: '#0d40a9'
    }
  },
  mounted () {
    this.color = window.$cookies.get('SET_BACKGROUND') ? window.$cookies.get('SET_BACKGROUND') : '#0d40a9'
    this.language = window.$cookies.get(LANGUAGE_SETTING_APP) || 'en'
    window.$i18n.locale = this.language
    moment.locale(this.language)
  },
  computed: {
    onCheckActive () {
      let currentPath = this.$route.name
      let menu = null
      this.MENUS.map((elm, indexMEnu) => {
        let index = elm.pathActive.findIndex(elm => elm === currentPath)
        if (index >= 0) {
          menu = this.MENUS[indexMEnu]
        }
      })
      if (menu && (menu.subMenu && menu.subMenu.length > 1)) {
        return true
      } else {
        return false
      }
    },
    styleObject () {
      let bgActive = '#e5f0ff'
      let colorRgb = this.color ? this.hexToRgb(this.color) : window.$cookies.get('SET_BACKGROUND')
      if (colorRgb && colorRgb.length > 15) {
        bgActive = colorRgb.replace('1)', '0.09)')
      } else {
        bgActive = '#e5f0ff'
      }
      return {
        '--color-custom': colorRgb,
        '--color-custom-bg': bgActive
      }
    }
  },
  methods: {
    setLanguage () {
      window.$cookies.set(LANGUAGE_SETTING_APP, this.language)
      window.$i18n.locale = this.language
      moment.locale(this.language)
      window.location.reload()
    },
    onSetBackground () {
      window.$cookies.set('SET_BACKGROUND', this.color)
    },
    hexToRgb (hex) {
      let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
      hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b
      })
      let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, 1)` : null
    },
    onLogout () {
      let confirmCallback = () => {
        let successCallback = (response) => {
          if (response.status === 200) {
            this.$cookies.set(APP_TOKEN_NAME, '')
            this.$cookies.set(USER_INFO_KEY, '')
            this.$cookies.remove(generateStorageKey(USER_INFO_KEY))
            this.$cookies.remove(generateStorageKey(APP_TOKEN_NAME))
            this.$cookies.remove(generateStorageKey(APP_TOKEN_NAME, true), null, process.env.VUE_APP_COOKIE_DOMAIN)
            this.$router.push('/login')
          }
        }
        let errorCallback = (error) => {
          this.$store.commit('addAlertItem', {
            type: 'error',
            content: error.response.data.messages[0]
          })
        }
        let payload = {
          successCallback,
          errorCallback
        }
        this.$store.dispatch('auth/logOut', payload)
      }
      let payloadConfirm = {
        callback: confirmCallback,
        message: this.$t('headerLayout.confirmLogout')
      }
      this.$store.commit('openConfirm', payloadConfirm)
    }
  }
}

</script>

<style lang="scss">
.main-slim{
  padding: 0 0 0 75px !important;
}
.main {
  margin-top: 75px;
  display: flex;
  flex-direction: column;
  $main: &;
  .main-content {
    padding-top: 0 !important;
    margin-left: 240px;
    position: relative;
    .container-fluid{
      padding-right: 20px;
      padding-left: 20px;
    }
    &-body{
      min-height: calc(100vh - 135px);
    }
  }
  &__content {
    margin: 0 60px;
    padding: 0 75px 30px;
    background: #F5F5F5;
    flex-grow: 1;

    #{$main}--no-padding & {
      padding-right: 0;
      padding-left: 0;
    }

    &__breadcrumbs {
      @include regular-font(14px);

      line-height: 20px;
      text-align: left;
      color: #999999;
      padding: 24px 20px;

      a {
        color: #999999;
        text-decoration: none;
        cursor: pointer;
      }

      span {
        color: $black-color;
      }

      .mark {
        color: #999999;
      }
    }

    &__title {
      @include regular-font(25px);

      color: $sub-primary-color;
      line-height: 52px;
      text-align: center;
      margin-top: 0;

      .help-button {
        float: right;
      }
    }

    &__actions {
      margin-top: 47px;
    }
  }
  &-title{
    text-transform: uppercase;
    color: #031b4e;
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 15px;
  }
}
.main-container{
  .dropdown .dropdown-menu {
    top:64px !important
  }
}
.page-wrapper{
  background-color: var(--color-custom) !important;
  .header-actions{
    i{
      color: var(--color-custom) !important;
    }
  }
  .table{
    thead{
      th{
        background-color: var(--color-custom) !important;
      }
    }
    tbody{
      .row-header{
        background-color: var(--color-custom) !important;
      }
    }
  }

  .thead {
    span {
      background-color: var(--color-custom) !important;
    }
  }
  .pagination-old__paginator ul li.active .page-link{
    background-color: var(--color-custom) !important;
    border-color: var(--color-custom) !important;
  }
  .sidebar-menu ul li a.current-page{
    color: var(--color-custom) !important;
    box-shadow: inset 3px 0 0 var(--color-custom) !important;
  }
  .sidebar-wrapper .sidebar-tabs .nav a.nav-link.active , .stats-tile2 .sale-icon {
    background-color: var(--color-custom-bg);
    i{
      color: var(--color-custom) !important;
    }
  }
  .el-button--primary, .btn-primary{
    background-color: var(--color-custom) !important;
    border-color: var(--color-custom) !important;
  }
  .stats-tile2 .sale-details,
  .stats-tiles-list .tiles-list h2,
  .sidebar-wrapper .sidebar-tabs .tab-content > .tab-pane .tab-pane-header ,
  .field-placeholder, .field-wrapper .field-placeholder-top {
    color: var(--color-custom) !important;
  }
  .graph-day-selection .btn.active{
    color: var(--color-custom) !important;
    background-color: var(--color-custom-bg);
  }
  .form-check-input:checked{
    background-color: var(--color-custom);
    border-color: var(--color-custom);;
  }
  .dialog-custom .el-dialog__header{
    background-color: var(--color-custom);
  }
  .text-pages{
    background-color: var(--color-custom);
    color: white;
  }
}
.tableItems{
  .table{
    tbody{
      tr{
        td{
          .item-status{
            &.using{
              background-color: var(--color-custom) !important;
            }
          }
        }
      }
    }
  }
}
.custom-select .el-select .el-tag{
  background-color: var(--color-custom) !important;
}
</style>
