<template>
  <div class="app app-error align-content-stretch d-flex flex-wrap">
    <div class="app-error-info">
      <h5>Oops!</h5>
      <span>It seems that the page you are looking for no longer exists.<br>
                We will try our best to fix this soon.</span>
      <a @click="$router.push('/')" class="btn btn-dark">Go to dashboard</a>
    </div>
    <div class="app-error-background"></div>
  </div>
</template>
<script>
export default {
  name: 'NotFoundPage',
  head: {
    title: function () {
      return {
        inner: 'NotFound'
      }
    }
  }
}
</script>
