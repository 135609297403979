<template>
  <div class="modal"  :class="{'show' :  confirm.show}" v-show="confirm.show">
    <div class="modal-backdrop" @click.self="cancel()"></div>
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content tx-size-sm">
        <div class="modal-body tx-center p-4">
          <button aria-label="Close"
                  @click="cancel()"
                  class="close"
                  data-dismiss="modal"
                  type="button"><span
                  aria-hidden="true">×</span></button>
          <h4 class="tx-danger mb-3">
            <i class="el-icon-warning"></i>
            {{ confirm.title ? confirm.title : $t('popup.titleConfirmWarning') }}
          </h4>
          <p class="my-3" v-html="confirm.message"></p>
          <div class="text-right">
            <Button
                type="danger"
                @click="cancel()">
              {{ $t('popup.buttonCancel') }}
            </Button>
            <Button
                type="success"
                classes="ml-2"
                @click="yesHandle()">
              {{ $t('popup.buttonOk') }}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Button from './Button'

export default {
  name: 'confirm',
  components: { Button },
  methods: {
    cancel () {
      this.$store.commit('closeConfirm')
    },
    yesHandle () {
      if (typeof this.confirm.callback === 'function') {
        this.confirm.callback()
      }
      this.cancel()
    }
  },
  computed: {
    ...mapState(['confirm'])
  }
}
</script>

<style lang="scss">
  .modal-open {
    overflow: hidden;

    .modal {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    &.show{
      opacity: 1;
      display: block;
    }
  }

  .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
  }

  .modal {
    &.fade .modal-dialog {
      transition: transform 0.3s ease-out;
      transform: translate(0, -50px);
    }

    &.show .modal-dialog {
      transform: none;
    }
  }

  @media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
      transition: none;
    }
  }

  .modal-dialog-scrollable {
    display: flex;
    max-height: calc(100% - 1rem);

    .modal-content {
      max-height: calc(100vh - 1rem);
      overflow: hidden;
    }

    .modal-header, .modal-footer {
      flex-shrink: 0;
    }

    .modal-body {
      overflow-y: auto;
    }
  }

  .modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);

    &::before {
      display: block;
      height: calc(100vh - 1rem);
      content: "";
    }

    &.modal-dialog-scrollable {
      flex-direction: column;
      justify-content: center;
      height: 100%;

      .modal-content {
        max-height: none;
      }

      &::before {
        content: none;
      }
    }
  }

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
    z-index: 9999;
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.4);

    &.fade {
      opacity: 0;
    }

    &.show {
      opacity: 0.5;
    }
  }

  .modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #ddd;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;

    .close {
      padding: 1rem 1rem;
      margin: -1rem -1rem -1rem auto;
    }
  }
  .close {
    float: right;
    font-size: 1.3125rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    background-color: transparent;
    border: 0;
  }

  .modal-title {
    margin-bottom: 0;
    line-height: 1.5;
  }

  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
  }

  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #ddd;
    border-bottom-right-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;

    > :not(:first-child) {
      margin-left: .25rem;
    }
  }

  .modal-footer > :not(:last-child) {
    margin-right: .25rem;
  }

  .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
  }

  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 500px;
      margin: 1.75rem auto;
    }

    .modal-dialog-scrollable {
      max-height: calc(100% - 3.5rem);

      .modal-content {
        max-height: calc(100vh - 3.5rem);
      }
    }

    .modal-dialog-centered {
      min-height: calc(100% - 3.5rem);

      &::before {
        height: calc(100vh - 3.5rem);
      }
    }

    .modal-sm {
      max-width: 300px;
    }
  }

  @media (min-width: 992px) {
    .modal-lg, .modal-xl {
      max-width: 800px;
    }
  }

  @media (min-width: 1200px) {
    .modal-xl {
      max-width: 1140px;
    }
  }

</style>
