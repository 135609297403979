import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import ElementUI from 'element-ui'
import VueHighlightJS from 'vue-highlightjs'
import VueHead from 'vue-head'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'

import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/vendor/bs-select/bs-select.css'
import '@/assets/vendor/dropzone/dropzone.min.css'
import '@/assets/vendor/input-tags/tagsinput.css'

import '@/assets/css/bootstrap.min.css'
import '@/assets/fonts/style.css'
import '@/assets/css/main.scss'
import '@/assets/scss/index.scss'

import '@/assets/vendor/summernote/summernote-bs4.css'
import '@/assets/vendor/daterange/daterange.css'
import '@/assets/vendor/megamenu/css/megamenu.css'
import '@/assets/vendor/search-filter/search-filter.css'
import '@/assets/vendor/search-filter/custom-search-filter.css'
import '@/assets/vendor/datatables/dataTables.bs4.css'
import '@/assets/vendor/datatables/dataTables.bs4-custom.css'
import '@/assets/vendor/datatables/buttons.bs.css'

import moment from 'moment-timezone'
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueLodash, { name: 'ld', lodash: lodash })
let mapConfig = {
  load: {
    key: 'AIzaSyDaOulQACiJzBfqumbsqg_-vKha8fCnL-s',
    libraries: 'places',
    region: 'ja',
    language: 'ja'
  }
}

Vue.use(VueGoogleMaps, mapConfig)
Vue.use(require('vue-cookies'))
Vue.use(VueHead, {
  separator: '|',
  complement: '(Version 1.8)'
})
moment.locale('ja')
Vue.prototype.$moment = moment
Vue.use(VueHighlightJS)
Vue.config.productionTip = false
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})
Vue.use(require('vue-moment'))

window.$i18n = i18n

let vue = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
window.$cookies = vue.$cookies
window.$store = store
window.$vue = vue
