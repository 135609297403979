import moment from 'moment'
const formatDateTime = (format = 'YYYY-MM-DD HH:mm:ss', dateTime = null) => {
  let momentTime = dateTime ? moment(dateTime) : moment()
  return momentTime.format(format)
}

const formatDateUTC0 = (format, dateTime = null, lastHour = false) => {
  let d = new Date()
  let time = (new Date(dateTime)).getTime() + d.getTimezoneOffset() * 60 * 1000 * -1
  let momentTime = dateTime ? moment(time).utcOffset('+00:00') : moment()
  let date = momentTime.format(format).split('T')
  let dateStart = (d.getTimezoneOffset() / 60) < 0 ? moment(time).add(-1, 'days').format('YYYY-MM-DD') : moment(time).format('YYYY-MM-DD')
  if (!lastHour) {
    return dateStart + 'T' + (24 + (d.getTimezoneOffset() / 60) > 24 ? 24 + (d.getTimezoneOffset() / 60) - 24 : 24 + (d.getTimezoneOffset() / 60)) + ':00:00.000+00:00'
  } else {
    return date[0] + 'T' + (24 + (d.getTimezoneOffset() / 60) > 24 ? 24 + (d.getTimezoneOffset() / 60) - 24 : 23 + (d.getTimezoneOffset() / 60)) + ':59:59.000+00:00'
  }
}

const generateStorageKey = (key, scs = true) => {
  let environmentKey = scs ? process.env.VUE_APP_SCS_KEY_CONSTANT : process.env.VUE_APP_KEY_CONSTANT
  return environmentKey ? `${environmentKey}_${key}` : key
}

const formatDateTimeZoneWithHour = (format, dateTime = null) => {
  let momentTime = dateTime ? moment(dateTime).utcOffset('+00:00') : moment()
  return momentTime.format(format).replaceAll('Z', '.000Z')
}

const validEmail = (email) => {
  let re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

const validNumber = (number) => {
  let reg = new RegExp('^[0-9]+$')
  return reg.test(number)
}
const validFloatNumber = (number) => {
  let reg = /[+-]?([0-9]*[.])?[0-9]+/
  return reg.test(number) && !isNaN(number)
}

const validPhoneNumber = (phoneNumber) => {
  let reg = '^[0-9\\-\\+]{9,15}$'
  return reg.test(phoneNumber)
}

const exportData = (path, filename) => {
  const link = document.createElement('a')
  link.href = path
  if (filename) link.download = filename
  document.body.appendChild(link)
  link.click()
}

const convertUrlToBlob = (url) => {
  return fetch(url).then((response) => {
    return response.blob()
  }).then(blob => {
    return URL.createObjectURL(blob)
  }).catch(() => {
  })
}

const addComasNumber = (n) => {
  if (!n) return '0'
  return n.toLocaleString('en')
}

const convertToHalfsize = (chars) => {
  let ascii = ''
  for (let i = 0, l = chars.length; i < l; i++) {
    let c = chars[i].charCodeAt(0)
    if (c === 12290) {
      c = 46
    }
    if (c >= 0xFF00 && c <= 0xFFEF) {
      c = 0xFF & (c + 0x20)
    }

    ascii += String.fromCharCode(c)
  }

  return formatNumber(ascii)
}

const formatNumber = (n) => {
  return n !== null ? n.toString().replace(/[,]+/g, '') : n
}

const formatNumberJapan = (number, no) => {
  if ((typeof number) === 'number') {
    if (no > 0) {
      return number.toFixed(no).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    } else {
      if (number > 0) {
        return Math.round(number).toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(',')
      } else {
        return Math.round(number).toString().replace(/(\d{1,2}?)((\d{3})+)$/, '$1,$2')
      }
    }
  } else {
    return number
  }
}

const validNegativeNumber = (number, decimal = false, precision = 0) => {
  let regex = '^-?[0-9]{0,10}$'

  if (decimal) {
    if (precision) {
      regex = `^((-?[0-9]{0,10})|(-?[0-9]{1,10}\\.[0-9]{0,${precision}}?))$`
    } else {
      regex = '^((-?[0-9]{0,10})|(-?[0-9]{1,10}\\.[0-9]*?))$'
    }
  }

  let reg = new RegExp(regex)
  return reg.test(number)
}

const getFileByPathName = (pathName) => {
  return pathName ? new URL(process.env.VUE_APP_API_URL + '/storage/' + pathName).href : null
}

const cloneArrayObject = (array) => {
  return array.map((item) => {
    return Object.assign({}, item)
  })
}

export {
  validFloatNumber,
  formatDateTime,
  validEmail,
  validNumber,
  exportData,
  convertUrlToBlob,
  validPhoneNumber,
  addComasNumber,
  convertToHalfsize,
  formatNumber,
  formatNumberJapan,
  validNegativeNumber,
  getFileByPathName,
  formatDateUTC0,
  formatDateTimeZoneWithHour,
  generateStorageKey,
  cloneArrayObject
}
