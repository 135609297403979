<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title" v-if="$slots.header">
        <slot name="header"></slot>
      </h5>
      <slot name="body"></slot>
    </div>
    <div class="card-footer" v-if="$slots.action">
      <slot name="action"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card'
}
</script>

<style scoped lang="scss">
.card-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px !important;
}
</style>
