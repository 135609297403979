import CustomerList from '@/views/customer/index'
import EditCustomer from '@/views/customer/edit'
import { APP_TOKEN_NAME } from '@/config/constants'
const ifAuthenticated = (to, from, next) => {
  let token = window.$cookies.get(APP_TOKEN_NAME)
  if (to.path !== '/login' && token) {
    next()
  } else {
    next({ name: 'login' })
  }
}
export default [
  {
    path: '/customer',
    name: 'customerManagement',
    component: CustomerList,
    meta: {
      permission: []
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/customer/edit',
    name: 'editCustomer',
    component: EditCustomer,
    meta: {
      permission: []
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/customer/add',
    name: 'addCustomer',
    component: EditCustomer,
    meta: {
      permission: []
    },
    beforeEnter: ifAuthenticated
  }
]
