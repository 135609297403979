import { UPLOAD_FILE } from '@/api/admin/common'
import { GET_ALL_TOTAL_RECORD } from '@/api/admin/master'
export default {
  uploadFile ({ commit }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    UPLOAD_FILE({ payload }, successCallback, errorCallback)
  },

  getTotalRecordStatistic ({ commit }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    GET_ALL_TOTAL_RECORD({ payload }, successCallback, errorCallback)
  }
}
