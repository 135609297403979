import { GET_ALL_STAFF, GET_LIST_STAFF, GET_STAFF_BY_ID, CHANGE_STATUS_STAFF, DELETE_STAFF, CREATE_STAFF, UPDATE_STAFF } from '@/api/admin/staff'

export default {
  getListStaff ({ commit }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    GET_LIST_STAFF({ payload }, successCallback, errorCallback)
  },
  getStaffById ({ commit }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    GET_STAFF_BY_ID({ payload }, successCallback, errorCallback)
  },
  createStaff ({ commit }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    CREATE_STAFF({ payload }, successCallback, errorCallback)
  },
  updateStaff ({ commit }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    UPDATE_STAFF({ payload }, successCallback, errorCallback)
  },
  deleteStaff ({ commit }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    DELETE_STAFF({ payload }, successCallback, errorCallback)
  },
  changeStatusStaff ({ commit }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    CHANGE_STATUS_STAFF({ payload }, successCallback, errorCallback)
  },
  getAllListStaff ({ commit }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    GET_ALL_STAFF({ payload }, successCallback, errorCallback)
  }
}
