<template>
  <el-input
      :class="{ 'input-error': error, 'input-number': true }"
      :value="displayValue"
      :readonly="readonly"
      :placeholder="placeholder"
      @input="regexNumber($event)"
      @change="didChangeInput"
      @blur="didChangeInput"
      :disabled="disabled">
    <template slot="append" v-if="prevText">
      {{prevText}}
    </template>
  </el-input>
</template>

<script>
import {
  convertToHalfsize,
  validNumber,
  formatNumber,
  validNegativeNumber
} from '@/utils'

export default {
  name: 'InputFieldNumber',
  props: {
    number: {
      require: true
    },
    appendText: {
      default: '',
      required: false
    },
    error: {
      require: false
    },
    placeholder: {
      require: false
    },
    decimal: {
      require: false,
      default: false
    },
    isNegative: {
      require: false,
      default: false
    },
    disabled: {
      require: false
    },
    readonly: {
      require: false
    },
    prevText: {
      default: '',
      type: String,
      required: false
    },
    label: {
      require: false,
      default: ''
    },
    isRequired: {
      require: false,
      default: false
    }
  },
  model: {
    prop: 'number',
    event: 'update'
  },
  data () {
    return {
      displayValue: ''
    }
  },
  created () {
    if (this.number !== '' && this.number !== null && this.number >= 0) {
      let splitString = this.number.toString().split('.')
      splitString[0] = splitString[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      this.displayValue = splitString.join('.')
    }
  },
  methods: {
    didChangeInput () {
      this.$emit('change')
    },
    regexNumber ($event) {
      $event = convertToHalfsize($event)
      if ($event === '') {
        this.displayValue = $event
      } else {
        if (this.decimal) {
          let check = this.isNegative ? validNegativeNumber($event, true, 3) : validNumber($event, true, 3)
          if (check) {
            let splitString = $event.toString().split('.')
            splitString[0] = splitString[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            this.displayValue = splitString.join('.')
          }
        } else {
          let check = this.isNegative ? validNegativeNumber($event) : validNumber($event)
          if (check) {
            this.displayValue = $event.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
          }
        }
      }
      let value = parseFloat(formatNumber(this.displayValue))
      if (value <= -1 || value >= 1) {
        this.displayValue = this.displayValue.replace(/^0+/g, '')
        this.displayValue = this.displayValue.replace(/^,+/g, '')
        if (value < -1) {
          this.displayValue = this.displayValue.replace(/-/g, '')
          this.displayValue = this.displayValue.replace(/^0/g, '')
          this.displayValue = this.displayValue.replace(/^,+/g, '')
          this.displayValue = `-${this.displayValue}`
        }
      } else {
        if (value === 0) {
          if (this.displayValue.indexOf('-') > -1) {
            this.displayValue = this.displayValue.replace(/-/g, '')
            this.displayValue = this.displayValue.replace(/,/g, '')
            this.displayValue = this.displayValue.replace(/^0+/g, '')
            this.displayValue = `-0${this.displayValue}`
          } else {
            this.displayValue = this.displayValue.replace(/,/g, '')
            this.displayValue = this.displayValue.replace(/^0+/g, '')
            this.displayValue = `0${this.displayValue}`
          }
        } else {
          if (value > 0) {
            this.displayValue = this.displayValue.replace(/,/g, '')
            this.displayValue = this.displayValue.replace(/^0+/g, '')
            this.displayValue = `0${this.displayValue}`
          }
          if (value < 0) {
            this.displayValue = this.displayValue.replace(/-/g, '')
            this.displayValue = this.displayValue.replace(/,/g, '')
            this.displayValue = this.displayValue.replace(/^0+/g, '')
            this.displayValue = `-0${this.displayValue}`
          }
        }
      }
      this.$emit('update', formatNumber(this.displayValue))
    }
  },
  watch: {
    number: {
      handler: function () {
        let splitString = this.number === null ? [''] : this.number.toString().split('.')
        splitString[0] = splitString[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        this.displayValue = splitString.join('.')
      }
    }
  }
}
</script>

<style lang="scss">

</style>
