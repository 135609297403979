<template>
  <Main>
    <template #body>
      <Card class="page-plan">
        <template #header>
          {{ isEdit ? $t('menu.registerInfo.edit') : $t('menu.registerInfo.add')}}
        </template>
        <template #body>
          <form>
            <el-divider content-position="left">
              <i class="el-icon-star-on"></i> Basic Info
            </el-divider>
            <el-row :gutter="20">
              <el-col :span="8" :lg="8" :xs="24">
                <InputField>
                  <template #label>
                    {{$t('registerInfo.customer')}}
                    <span class="text-danger">*</span>
                  </template>
                  <template #input>
                    <CustomSelect
                        v-model="registrationRecord.idCustomer"
                        @change="onGetDataDomain(registrationRecord.idCustomer)">
                      <template #options>
                        <el-option
                            v-for="customer in listCustomer"
                            :key="`customer_${customer.id}`"
                            :value="customer.id"
                            :label="`[${customer.code}] ${customer.full_name}`"
                        ></el-option>
                      </template>
                    </CustomSelect>
                  </template>
                </InputField>
              </el-col>
              <el-col :span="8" :lg="8" :xs="24">
                <InputField>
                  <template #label>
                    {{$t('registerInfo.domainName')}}
                    <span class="text-danger">*</span>
                  </template>
                  <template #input>
                    <CustomSelect v-model="registrationRecord.idDomain">
                      <template #options>
                        <el-option
                            v-for="domain in listDomain"
                            :key="`domain_${domain.ID}`"
                            :value="domain.ID"
                            :label="`${domain.name_app} (${domain.domain})`">
                        </el-option>
                      </template>
                    </CustomSelect>
                  </template>
                </InputField>
              </el-col>
              <el-col :span="8" :lg="8" :xs="24">
                <InputField>
                  <template #label>
                    {{$t('registerInfo.pluginName')}}
                    <span class="text-danger">*</span>
                  </template>
                  <template #input>
                    <CustomSelect v-model="registrationRecord.idPlugin">
                      <template #options>
                        <el-option
                            v-for="plugin in listPlugin"
                            :key="`plugin_${plugin.id}`"
                            :value="plugin.id"
                            :label="`[${plugin.code}] ${plugin.name}`"
                        ></el-option>
                      </template>
                    </CustomSelect>
                  </template>
                </InputField>
              </el-col>
            </el-row>
            <el-divider content-position="left">
              <i class="el-icon-star-on"></i> Rent Info
            </el-divider>
            <el-row :gutter="20">
              <el-col :span="8" :lg="8" :xs="24">
                <InputField>
                  <template #label>
                    {{$t('registerInfo.registerDate')}}
                    <span class="text-danger">*</span>
                  </template>
                  <template #input>
                    <el-date-picker
                        @change="onSetBillingStartDate"
                        v-model="registrationRecord.registrationDate"
                        class="w-100">
                    </el-date-picker>
                  </template>
                </InputField>
              </el-col>
              <el-col :span="8" :lg="8" :xs="24">
                <InputField>
                  <template #label>
                    {{$t('textCommon.circleRent')}}
                    <span class="text-danger">*</span>
                  </template>
                  <template #input>
                    <CustomSelect
                        v-model="registrationRecord.circleRent"
                        @change="onSetExpiredDate">
                      <template #options>
                        <el-option
                            v-for="item in planRent"
                            :key="item.id" :label="`${item.numberMonth} ${$t('textCommon.month')}`"
                            :value="item.id">
                        </el-option>
                      </template>
                    </CustomSelect>
                  </template>
                </InputField>
              </el-col>
              <el-col :span="8" :lg="8" :xs="24">
                <InputField>
                  <template #label>
                    {{$t('registerInfo.billingStartDate')}}
                    <span class="text-danger">*</span>
                  </template>
                  <template #input>
                    <el-date-picker
                        disabled=""
                        v-model="registrationRecord.billingStartDate"
                        class="w-100">
                    </el-date-picker>
                  </template>
                </InputField>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8" :lg="8" :xs="24">
                <InputField>
                  <template #label>
                    {{$t('registerInfo.extensionDate')}}
                    <span class="text-danger">*</span>
                  </template>
                  <template #input>
                    <el-date-picker
                        disabled
                        v-model="registrationRecord.extensionDate"
                        class="w-100">
                    </el-date-picker>
                  </template>
                </InputField>
              </el-col>
              <el-col :span="8" :lg="8" :xs="24">
                <InputField>
                  <template #label>
                    {{$t('registerInfo.expiredDate')}}
                    <span class="text-danger">*</span>
                  </template>
                  <template #input>
                    <el-date-picker
                        disabled=""
                        v-model="registrationRecord.expiredDate"
                        class="w-100">
                    </el-date-picker>
                  </template>
                </InputField>
              </el-col>
              <el-col :span="8" :xs="24">
                <Button
                    v-if="isEdit"
                    type="primary"
                    classes="btn-small mt-3"
                    @click="handleExtensionDate">
                  Extension Bill
                </Button>
              </el-col>
            </el-row>
            <el-divider content-position="left">
              <i class="el-icon-star-on"></i>
              Status Info
            </el-divider>
            <el-row :gutter="20">
              <el-col :span="8" :lg="8" :xs="24">
                <InputField>
                  <template #label>
                    {{$t('registerInfo.status')}}
                    <span class="text-danger">*</span>
                  </template>
                  <template #input>
                    <CustomSelect v-model="registrationRecord.status">
                      <template #options>
                        <el-option
                            v-for="item in listStatus"
                            :key="item.value" :label="`${item.label}`"
                            :value="item.value"
                        ></el-option>
                      </template>
                    </CustomSelect>
                  </template>
                </InputField>
              </el-col>
              <el-col :span="8" :lg="8" :xs="24">
                <InputField>
                  <template #label>{{$t('registerInfo.totalPriceRent')}}</template>
                  <template #input>
                    <InputNumber
                        v-model="registrationRecord.totalPrice"
                        disabled=""
                        prevText="円">
                    </InputNumber>
                  </template>
                </InputField>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <InputField>
                  <template #label>{{$t('pluginManagement.description')}}</template>
                  <template #input>
                    <el-input
                        v-model="registrationRecord.note"
                        type="textarea"
                        :rows="5">
                    </el-input>
                  </template>
                </InputField>
              </el-col>
            </el-row>
            <div class="text-right mt-3">
              <Button
                  classes="btn btn-outline-primary mr-3"
                  type="cancel"
                  @click="cancel">
                {{$t('button.cancel')}}
              </Button>
              <Button
                  v-loading="isLoading"
                  :disabled="isLoading"
                  classes="btn btn-primary"
                  type="submit"
                  @click="createRecord">
                {{isEdit? $t('button.save') :$t('button.register')}}
              </Button>
            </div>
          </form>
        </template>
      </Card>
    </template>
  </Main>
</template>

<script>
import Main from '@/components/partials/layout/Main'
import Card from '@/components/partials/Card'
import Button from '@/components/partials/Button'
import CustomSelect from '@/components/partials/forms/CustomSelect'
import InputField from '@/components/partials/InputField'
import InputNumber from '@/components/partials/forms/InputNumber'
import { addMonths, lastDayOfMonth } from 'date-fns'
import { LIST_STATUS_BILL } from '@/config/constants'
import { formatDateTime } from '@/utils'
export default {
  name: 'staffManagement',
  components: { Button, Card, Main, CustomSelect, InputField, InputNumber },
  data () {
    return {
      isEdit: false,
      isLoading: false,
      isLoadingDelete: false,
      registrationRecord: {
        id: null,
        code: null,
        idCustomer: null,
        idDomain: null,
        idPlugin: null,
        registrationDate: null,
        circleRent: null,
        expiredDate: null,
        billingStartDate: null,
        status: 1,
        totalPrice: 0,
        note: null,
        extensionDate: null
      },
      errorInfo: {
        idCustomer: null,
        idDomain: null,
        idPlugin: null,
        registrationDate: null,
        circleRent: null,
        expiredDate: null,
        status: 1
      },
      listPlugin: [],
      listDomain: [],
      listCustomer: []
    }
  },
  head: {
    title: function () {
      return {
        inner: this.$route.query.id ? this.$t('menu.registerInfo.edit') : this.$t('menu.registerInfo.add')
      }
    }
  },
  mounted () {
    this.$store.commit('setBreadCrumb', [
      { label: 'registerPlugin', link: 'registerPlugins' },
      { label: this.isEdit ? 'registerInfo.edit' : 'registerInfo.add' }])
  },
  created () {
    this.onGetAllPlugin()
    this.onGetAllCustomer()
  },
  computed: {
    planRent () {
      return [
        { id: 3, numberMonth: 3 },
        { id: 6, numberMonth: 6 },
        { id: 9, numberMonth: 9 },
        { id: 12, numberMonth: 12 }
      ]
    },
    listStatus () {
      return LIST_STATUS_BILL
    },
    dataCheckPrice () {
      let { idPlugin, circleRent, status } = this.registrationRecord
      let pricePerMonth = 0
      if (this.listPlugin) {
        let index = this.listPlugin.findIndex(elm => elm.id === idPlugin)
        if (index >= 0) {
          pricePerMonth = this.listPlugin[index].price_per_month
        }
      }
      let result = {
        pricePerMonth,
        circleRent,
        status
      }

      return result
    }
  },
  watch: {
    dataCheckPrice: {
      deep: true,
      handler (value) {
        if (value) {
          if (value.status === 1 || value.status === 3) {
            this.registrationRecord.totalPrice = 0
          } else {
            this.registrationRecord.totalPrice = Number(value.pricePerMonth) * Number(value.circleRent)
          }
        }
      }
    }
  },
  methods: {
    handleExtensionDate () {
      let { id, expiredDate, circleRent } = this.registrationRecord
      let payload = {
        successCallback: (response) => {
          if (response.status === 200) {
            let { data } = response.data
            if (data) {
              this.registrationRecord.expiredDate = data['expired_date']
              this.registrationRecord.extension_date = data['extension_date']
            }
          }
        },

        errorCallback: (err) => {
          this.$message({
            showClose: true,
            message: err.response.data.message ? err.response.data.message : '',
            type: 'error'
          })
        },
        data: {
          id: id,
          extension_date: new Date(),
          expired_date: lastDayOfMonth(addMonths(new Date(expiredDate), Number(circleRent)))
        }
      }
      this.$store.dispatch('registerInfo/updateExpiredDate', payload)
    },
    onSetExpiredDate () {
      let { billingStartDate, circleRent, status } = this.registrationRecord
      if (billingStartDate && circleRent && status === 1) {
        this.registrationRecord.expiredDate = lastDayOfMonth(addMonths(new Date(billingStartDate), Number(circleRent) - 1))
      }
    },
    onSetBillingStartDate () {
      let { registrationDate } = this.registrationRecord
      if (registrationDate) {
        this.registrationRecord.billingStartDate = addMonths(new Date(registrationDate), Number(1))
        this.onSetExpiredDate()
      }
    },
    onGetAllCustomer () {
      let successCallback = (response) => {
        if (response.status === 200) {
          this.listCustomer = response.data.data
          let idRouter = this.$route.query.id
          if (idRouter) {
            this.isEdit = true
            this.getRecordById(idRouter)
          }
        }
      }
      let errorCallback = () => {}
      let payload = {
        successCallback,
        errorCallback
      }
      this.$store.dispatch('customer/getAllCustomer', payload)
    },
    onGetAllPlugin () {
      let successCallback = (response) => {
        if (response.status === 200) {
          this.listPlugin = response.data.data
        }
      }
      let errorCallback = () => {}
      let payload = {
        successCallback,
        errorCallback
      }

      this.$store.dispatch('plugin/getAllListPlugin', payload)
    },

    onGetDataDomain (idRecord, isGet = false) {
      let result = []
      if (!isGet) {
        this.registrationRecord.idDomain = null
      }
      if (this.listCustomer && this.listCustomer.length > 0) {
        let index = this.listCustomer.findIndex(elm => elm.id === idRecord)
        if (index >= 0) {
          result = this.listCustomer[index].listDomain || []
        }
      }
      this.listDomain = result
    },
    createRecord () {
      this.isLoading = true
      let successCallback = (response) => {
        if (response.status === 200) {
          this.isLoading = false
          this.$message({
            showClose: true,
            message: !this.isEdit ? this.$t('registerInfo.msgAddSuccess') : this.$t('registerInfo.msgUpdateSuccess'),
            type: 'success'
          })
          this.$router.push('/register-plugin')
        }
      }

      let errorCallback = (err) => {
        if (err.response) {
          this.$message({
            showClose: true,
            message: err.response.data.message ? err.response.data.message : this.$t('textCommon.pluginWithDomainExisted'),
            type: 'error'
          })
        }
        this.isLoading = false
      }
      let data = Object.assign({}, this.registrationRecord)
      let dataPayload = {
        id: data.id || null,
        code: data.code || null,
        id_domain: data.idDomain,
        id_plugin: data.idPlugin,
        circle_rent: data.circleRent,
        register_date: data.registrationDate,
        expired_date: data.expiredDate,
        extension_date: data.extensionDate || null,
        status: data.status,
        note: data.note,
        total_price_rent: data.totalPrice,
        billing_start_date: data.billingStartDate
      }
      let payload = {
        successCallback,
        errorCallback,
        data: dataPayload
      }
      let action = !this.isEdit ? 'registerInfo/createRegisterInfo' : 'registerInfo/updateRegisterInfo'
      this.$store.dispatch(action, payload)
    },
    getRecordById (idRecord) {
      this.isLoading = true
      let successCallback = (response) => {
        if (response.status === 200) {
          this.isEdit = true
          this.isLoading = false
          let { data } = response.data
          this.registrationRecord.id = data.id
          this.registrationRecord.code = data.code
          this.registrationRecord.idDomain = data['id_domain']
          this.registrationRecord.idPlugin = data['id_plugin']
          this.registrationRecord.idCustomer = data.code
          this.registrationRecord.expiredDate = data['expired_date']
          this.registrationRecord.circleRent = data['circle_rent']
          this.registrationRecord.registrationDate = data['register_date']
          this.registrationRecord.status = data.status
          this.registrationRecord.totalPrice = data['total_price_rent']
          this.registrationRecord.note = data.note
          this.registrationRecord.billingStartDate = data['billing_start_date']
          this.registrationRecord.extensionDate = data['extension_date'] && this.convertDate(data['extension_date'], 'YYYY') !== '0001' ? data['extension_date'] : null
          if (data['AccountDomain']) {
            if (data['AccountDomain']['Customer'] && data['AccountDomain']['Customer'].ID) {
              let idCustomer = data['AccountDomain']['Customer'].ID
              this.registrationRecord.idCustomer = idCustomer
              this.onGetDataDomain(idCustomer, true)
            }
          }
        }
      }
      let errorCallback = () => {
        this.isLoading = false
      }
      let payload = {
        successCallback,
        errorCallback,
        id: idRecord
      }
      this.$store.dispatch('registerInfo/getRegisterInfoById', payload)
    },
    convertDate (date, format) {
      if (!date) {
        return null
      } else {
        let dateCheck = new Date(date)
        return formatDateTime(format, dateCheck)
      }
    },
    cancel () {
      this.isEdit = false
      this.isLoading = false
      this.$router.push('/register-plugin')
    }
  }
}
</script>
