'use strict'

import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { APP_TOKEN_NAME, USER_INFO_KEY } from '@/config/constants'

export default function (url, method, successCallback, errorCallback = null, defaultConfig = {}) {
  let config = {
    method,
    url,
    ...defaultConfig
  }

  let beforeCallback = () => {
    store.commit('increaseLoading')
  }

  let afterCallback = () => {
    store.commit('decreaseLoading')
  }

  let errorResponseCallback = (error) => {
    if (error.response.data.statusCode === 415 || error.response.data.statusCode === 401) {
      store.commit('SET_CODE_ERROR', 415)
      window.$cookies.set(APP_TOKEN_NAME, '')
      localStorage.removeItem(APP_TOKEN_NAME)
      window.$cookies.set(USER_INFO_KEY, '')
      router.push('/login')
    } else if (error.response.data.statusCode === 416 && error.response.data.message) {
      store.commit('SET_CODE_ERROR', 416)
      window.$cookies.set(APP_TOKEN_NAME, '')
      localStorage.removeItem(APP_TOKEN_NAME)
      window.$cookies.set(USER_INFO_KEY, '')
      router.push('/login')
    }
  }

  let officialErrorCallback = (error) => {
    afterCallback()

    if (typeof errorCallback === 'function') {
      errorCallback(error)
    }

    if (error.response) {
      errorResponseCallback(error)
    }

    return Promise.reject(error)
  }

  let officialSuccessCallback = (response) => {
    afterCallback()
    successCallback(response)
  }

  beforeCallback()

  return axios(config).then(
    (response) => officialSuccessCallback(response),
    (error) => officialErrorCallback(error)
  ).catch(() => {})
}
