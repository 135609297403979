import { CHANGE_STATUS_REGISTER_INFO, CREATE_REGISTER_INFO, DELETE_REGISTER_INFO, GET_LIST_REGISTER_INFO, GET_REGISTER_INFO_BY_ID, UPDATE_REGISTER_INFO, UPDATE_EXPIRED_DATE } from '@/api/admin/registerInfo'

export default {

  getListRegisterInfo ({ commit }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    GET_LIST_REGISTER_INFO({ payload }, successCallback, errorCallback)
  },

  getRegisterInfoById ({ commit }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    GET_REGISTER_INFO_BY_ID({ payload }, successCallback, errorCallback)
  },

  createRegisterInfo ({ commit }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    CREATE_REGISTER_INFO({ payload }, successCallback, errorCallback)
  },

  updateRegisterInfo ({ commit }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    UPDATE_REGISTER_INFO({ payload }, successCallback, errorCallback)
  },
  updateExpiredDate ({ commit }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    UPDATE_EXPIRED_DATE({ payload }, successCallback, errorCallback)
  },

  deleteRegisterInfo ({ commit }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    DELETE_REGISTER_INFO({ payload }, successCallback, errorCallback)
  },

  changeStatusRegisterInfo ({ commit }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    CHANGE_STATUS_REGISTER_INFO({ payload }, successCallback, errorCallback)
  }
}
