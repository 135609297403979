<template>
  <div class="box-upload-file">
    <div class="design_image form-control d-inline-flex align-items-center justify-content-center">
      <i class="el-icon-upload2 avatar-uploader-icon"/>
      <i
          class="text-danger fa-solid fa-close pos-absolute"
          v-if="value"
          @click="deleteImage()"> </i>
      <input
          type="file" style="display: none"
          class="cursor-pointer pos-absolute opacity-0 w-100 h-100 "
          name="upload_file"
          ref="file"
          @change="uploadFile"
          accept="image/jpeg, image/png"
      />
      <template v-if="value">
        <div
            :index="indexImage"
            class="imagePreviewWrapper"
            :style="{ 'background-image': `url(${value})` }"
            @click="selectImage">
        </div>
      </template>
      <template v-else>
        <div
            :index="indexImage"
            class="imagePreviewWrapper"
            @click="selectImage">
        </div>
      </template>
    </div>
    <div class="mt-1">{{nameFile}}</div>
  </div>
</template>
<script>
// Create form upload image
export default {
  data () {
    return {
      previewImage: null,
      nameFile: null
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    indexImage: {
      default: 0,
      type: [String, Number]
    },
    value: {
      type: String,
      default: ''
    }
  },
  components: {

  },
  methods: {
    selectImage () {
      this.$refs.file.click()
    },
    uploadFile () {
      let file = this.$refs.file.files
      let typeImage = ['image/jpeg', 'image/png', 'image/jpg', 'image/svg', 'image/heic', 'image/tiff']
      if (!typeImage.includes(file[0]['type'])) {
        this.$message({
          showClose: true,
          message: this.$t('textCommon.imageTypeFail'),
          type: 'error'
        })
        file = ''
        this.previewImage = ''
        this.value = ''
        this.$refs.file.value = null
      } else if (file[0]['size'] > 2000000) {
        this.$message({
          showClose: true,
          message: this.$t('textCommon.imageMaxSize'),
          type: 'error'
        })
        file = ''
        this.previewImage = ''
        this.value = ''
        this.$refs.file.value = null
      } else {
        let successCallback = (response) => {
          if (response.status === 200) {
            let urlImage = response.data.data.file_name || null
            this.$emit('change', process.env.VUE_APP_URL_IMAGE + urlImage)
          }
        }
        let errorCallback = (error) => {
          if (error.response) {
            let statusCode = error.response.data.code
            if (statusCode === 422) {
              this.$message({
                showClose: true,
                message: this.$t('textCommon.imageTypeFail'),
                type: 'error'
              })
            }
          }
        }
        let formData = new FormData()
        formData.append('name', 'images')
        formData.append('file', this.$refs.file.files[0])
        let payload = {
          successCallback,
          errorCallback,
          data: formData
        }
        this.$store.dispatch('uploadFile', payload)
      }
    },
    deleteImage () {
      this.$refs.file.value = null
      this.$emit('change', null)
    }
  }
}
</script>
<style lang="scss">
.box-upload-file{
  width: 200px;
  height: 230px;
  display: inline-block;
  .design_image{
    width: 100%;
    height: 100%;
    position: relative;

    .fa-close{
      top: 2px;
      right: 5px;
      z-index:3;
      font-weight: 800;
      font-size: 18px;
    }
  }
  .avatar-uploader-icon{
    font-size: 45px;
    opacity: .5;
  }
  .imagePreviewWrapper {
    position: absolute;
    z-index: 2;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}
</style>
