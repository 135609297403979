<template>
  <div
      class="field-wrapper"
      :class="`${classes} ${sub ? 'input-field--sub':''}`">
    <div
        :class="{'field-placeholder': !labelTop , 'field-placeholder-top':labelTop}"
        v-if="$slots.label">
      <slot name="label"></slot>
    </div>
    <div
        class="w-100"
        :class="{'input-error-box':$slots.error}">
      <slot name="input"></slot>
    </div>
    <div
        v-if="$slots.error"
        class="invalid-feedback text-error"
        :class="{'d-block': $slots.error }">
      <div class="d-flex align-items-center">
        <slot name="error"></slot>
        <span class="icon-error el-icon-warning-outline"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'inputField',
  props: {
    sub: {
      type: Boolean,
      required: false
    },
    labelTop: {
      default: false,
      required: false,
      type: Boolean
    },
    classes: {
      default: '',
      required: false,
      type: String
    }
  }
}
</script>

<style lang="scss">
.input-field {
  $inputField: &;

  &--sub {
    #{$inputField}__inner {
      width: 64%;
      float: right;
    }
  }

  &__label {
    @include regular-font(18px);
    float: left;
    width: calc(30% - 10px);
    line-height: 26px;
    text-align: right;
    padding-right: 50px;
    margin: 12px 10px 0 0;
  }

  &__input {
    float: left;
    width: 64%;
    line-height: 50px;

    .el-select {
      display: block;
    }
    .el-textarea{
      margin-bottom: 8px;
    }

    &__error {
      @include regular-font(12px);

      line-height: 1.7;
      min-height: 16px;
      margin-bottom: 1rem;
      color: rgba(229, 0, 0, 0.7);
      text-align: left;
    }

    &--error {
      input[type="text"], input[type="number"], input[type="tel"], input[type="email"], textarea {
        border-color: rgba(229, 0, 0, 0.7) !important;
        background: rgba(238, 177, 177, 0.7) !important;
        color: #fff !important;
        &:-moz-placeholder {
          color: #fff !important;
        }
        &:-ms-input-placeholder{
          color: #fff !important;
        }
        &::-webkit-input-placeholder{
          color: #fff !important;
        }
      }

    }
  }
}
.field-wrapper{
  .field-placeholder{
    //box-shadow:  0px -.1px 1px #888;
  }
  .field-placeholder-top{
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #1273eb;
    background: #ffffff;
    border-radius: 30px;
    font-size: .75rem;
    font-weight: 600;
    padding: 2px 7px;
    z-index: 10;
    text-align: left;
    margin-bottom: 6px;
  }
  .input-error-box{
    input[type="text"], input[type="number"], input[type="tel"], input[type="email"], textarea{
      border-color: #dc3545 !important;
      padding-right: calc(1.5em + 0.75rem);
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  }
  .w-100{
    .el-select{
      width: 100%;
    }
  }
  .text-error{
    .icon-error{
      color: red;
      margin-left: 2px;
      font-weight: 500;
      font-size: 16px;
    }
  }
}
</style>
