export default {
  call_request (state) {
    state.status = 'loading'
  },
  call_success (state, payload) {
    state.status = 'success'
    state.listRecord = payload.listRecord
  },
  call_error (state) {
    state.status = 'error'
    state.listRecord = null
  },
  call_loading (state, loading) {
    state.loading = loading
  }
}
