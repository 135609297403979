<template>
  <div class="non-siderbar">
    <div class="login-container">
      <div class="container-fluid h-100">
        <div class="row g-0 h-100">
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="login-wrapper">
              <form>
                <div class="login-screen">
                  <div class="login-body mb-5">
                    <a href="/" class="login-logo">
                      <img src="@/assets/images/app_icon_default.png" alt="admin-plugin-logo">
                      <span>Plugin Admin</span>
                    </a>
                    <h6>Welcome back,<br>Please login to your account.</h6>
                    <div class="field-wrapper">
                      <input
                          v-model="email" ref="email"
                          type="email" @keyup.enter="onSelectPassword"
                          class="form-control" @keyup="validate('email')"
                           @keydown="validate('email')"
                          :class="{'form-error':errorInfo.email}" >
                      <div class="field-placeholder">{{$t('pageLogin.email')}}
                        <span class="text-danger">*</span>
                      </div>
                      <div
                          class="invalid-feedback"
                          :class="{'d-block': errorInfo.email }"
                          v-if="errorInfo.email">
                        {{errorInfo.email}}
                      </div>
                    </div>
                    <div class="field-wrapper mb-3">
                      <el-input
                          :class="{'form-error-login':errorInfo.password}"
                          v-model="password"
                          ref="password" @keyup.native="validate('password')"
                          show-password  @keydown.native="validate('password')"
                          @keyup.native.enter="login"
                          type="password">
                      </el-input>
                      <div class="field-placeholder">
                        {{$t('pageLogin.password')}}
                        <span class="text-danger">*</span>
                      </div>
                      <div
                          class="invalid-feedback"
                          :class="{'d-block': errorInfo.password }"
                          v-if="errorInfo.password">
                        {{errorInfo.password}}
                      </div>
                    </div>
                    <div class="actions">
                      <button type="button" @click="login" class="btn btn-primary w-100">{{$t('button.signIn')}}</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { APP_TOKEN_NAME, LANGUAGE_SETTING_APP, LIST_LANGUAGE } from '@/config/constants'
import { validEmail, generateStorageKey } from '@/utils'

export default {
  name: 'login',
  data () {
    return {
      email: '',
      password: '',
      company_code: '',
      listLanguage: LIST_LANGUAGE,
      errorInfo: {
        email: '',
        password: '',
        company_code: ''
      }
    }
  },
  mounted () {
    let defaultLanguage = window.$cookies.get(LANGUAGE_SETTING_APP)
    if (defaultLanguage) {
      return false
    } else {
      if (window.navigator.language) {
        let langBrower = window.navigator.language || ''
        if (langBrower) {
          let index = this.listLanguage.findIndex(elm => elm.key === langBrower.slice(0, 2))
          if (index >= 0) {
            window.$cookies.set(LANGUAGE_SETTING_APP, langBrower.slice(0, 2))
            window.$i18n.locale = langBrower.slice(0, 2)
          } else {
            window.$cookies.set(LANGUAGE_SETTING_APP, 'ja')
            window.$i18n.locale = 'ja'
          }
        } else {
          window.$cookies.set(LANGUAGE_SETTING_APP, 'ja')
          window.$i18n.locale = 'ja'
        }
      }
    }

    let token = this.$cookies.get(generateStorageKey(APP_TOKEN_NAME))
    if (token) {
      this.$router.push({ path: '/' })
    }
  },
  methods: {
    goPath (path) {
      if (path) {
        this.$router.push(path)
      }
    },
    onSelectPassword () {
      this.$refs['password'].focus()
      this.$refs['password'].select()
    },
    login: function () {
      let check = this.validate()
      if (check) {
        this.isLoading = true
        let successCallback = (response) => {
          let { data } = response.data
          let dataToken = data ? data.token : null
          if (dataToken) {
            localStorage.setItem(APP_TOKEN_NAME, dataToken)
            this.$cookies.set(APP_TOKEN_NAME, dataToken)
            this.$router.push({ name: 'dashboard' })
            this.$message({
              showClose: true,
              message: this.$t('admin.form.loginSuccess'),
              type: 'success'
            })
            this.isLoading = false
          }
        }
        let errorCallback = (error) => {
          if (error) {
            this.$message({
              showClose: true,
              message: this.$t('admin.form.loginFail'),
              type: 'error'
            })
          }
        }
        let payload = {
          data: {
            email: this.email,
            password: this.password
          },
          successCallback,
          errorCallback
        }

        this.$store.dispatch('auth/login', payload)
      }
    },
    validate (field = '') {
      let check = true
      if (field === 'email' || !field) {
        if (this.email === '') {
          this.errorInfo.email = this.$t('msg.required', { field: this.$t('pageLogin.email') })
          check = false
        } else if (!validEmail(this.email)) {
          this.errorInfo.email = this.$t('msg.emailValid')
          check = false
        } else {
          this.errorInfo.email = ''
        }
      }

      if (field === 'password' || !field) {
        if (this.password === '') {
          this.errorInfo.password = this.$t('msg.required', { field: this.$t('pageLogin.password') })
          check = false
        } else {
          this.errorInfo.password = ''
        }
      }

      return check
    }
  }
}
</script>
