const APP_TOKEN_NAME = 'token'
const USER_INFO_KEY = 'userInfo'
const PER_PAGE_LIST = [10, 20, 30, 50, 100]
const HORIZONTAL = 'HORIZONTAL'
const FILTER_SAVE = 'OS_FILTER_PLUGIN'
const FILTER_SAVE_NORMAL = 'OS_FILTER_NORMAL'
const ACTION_LIST_CUSTOMER = 'customer/getListCustomer'
const LANGUAGE_SETTING_APP = 'languageApp'
const LIST_STATUS = [
  { value: 'active', text: 'textCommon.status.active' },
  { value: 'inactive', text: 'textCommon.status.deActive' }
]
const LIST_STATUS_CUSTOMER = [
  { value: 1, text: 'textCommon.status.active' },
  { value: 0, text: 'textCommon.status.deActive' }
]
const LIST_ROLE = [
  { value: 'admin', text: 'textCommon.roles.admin' },
  { value: 'staff', text: 'textCommon.roles.staff' }
]
const LIST_STATUS_BILL = [
  { value: 1, label: 'Free Trail' },
  { value: 2, label: 'Using' },
  { value: 3, label: 'Expired' }
]

const PASSENGER_ADULT = 'Adult'
const STATUS_AIRPORT_OPEN = 'operable'
const STATUS_AIRPORT_CLOSE = 'unoperable'
const STATUS_AIRPORT_RESTRICTED = 'restricted'
const TIMING_LOADER = 200

const SECRET_KEY = '1ykJe4fvk4jPn7GpPJh62hoe687e6vow'
const DATE_FORMAT_SEND_TO_SERVER = 'yyyy/mm/dd HH:mm:ss'

const LIST_LANGUAGE = [
  {
    label: 'Tiếng Việt',
    key: 'vi'
  },
  {
    label: '日本語',
    key: 'ja'
  },
  {
    label: 'English',
    key: 'en'
  }

]

export {
  APP_TOKEN_NAME,
  USER_INFO_KEY,
  PER_PAGE_LIST,
  HORIZONTAL,
  FILTER_SAVE,
  FILTER_SAVE_NORMAL,
  LIST_STATUS,
  LIST_ROLE,
  ACTION_LIST_CUSTOMER,
  LIST_STATUS_CUSTOMER,
  PASSENGER_ADULT,
  STATUS_AIRPORT_OPEN,
  STATUS_AIRPORT_CLOSE,
  STATUS_AIRPORT_RESTRICTED,
  TIMING_LOADER,
  SECRET_KEY,
  LANGUAGE_SETTING_APP,
  DATE_FORMAT_SEND_TO_SERVER,
  LIST_LANGUAGE,
  LIST_STATUS_BILL
}
