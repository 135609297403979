<template>
  <Main class="material_list">
    <template #body>
      <div class="user-page__header mb-3">
        <div class="d-flex justify-content-end">
            <Button
                type="primary"
                @click="goPath('/plugins/add')">
              <i class="el-icon-plus"></i>
              {{$t('button.addMore')}}
            </Button>
        </div>
      </div>
      <Card>
        <template #header>
          {{$t('menu.plugin.list')}}
        </template>
        <template #body>
          <TableBox
              :fieldTableName="$t('pageMaterial.field')"
              :data-list.sync="dataList"
              :config="config"
              :reload="isReload">
            <template #searchBox>
              <BoxSearch
                  v-model="config.q"
                  :placeholder="$t('placeholderSearch', {field: $t('input.placeholderSearchProduct')})"/>
            </template>
            <template #row="{ row }">
              <tr>
                <td>{{row.code}}</td>
                <td>{{row.name}}</td>
                <td>
                  {{row.developer}}
                </td>
                <td>
                  <FormatDate format="YYYY/MM" :date="row.created_year"/>
                </td>
                <td>
                  <FormatNumber :number="row.price_per_month"></FormatNumber>
                </td>
                <td class="text-center column-action" width="8%">
                  <a
                      href="javascript:void(0)"
                      :title="$t('btn.edit')"
                      @click="goToEdit('/plugins/edit', row.id)">
                    <i class="el-icon-edit-outline"></i>
                  </a>
                  <a
                      class="text-danger"
                      :title="$t('btn.delete')"
                      href="javascript:void(0)"
                      :class="{'icon-disabled': onCheckDisabled(row)}"
                     @click="deleteObject($event, row)">
                    <i class="el-icon-circle-close"></i>
                  </a>

                </td>
              </tr>
            </template>

          </TableBox>

        </template>
      </Card>
    </template>
  </Main>
</template>

<script>
import Main from '@/components/partials/layout/Main'
import Button from '@/components/partials/Button'
import TableBox from '@/components/partials/TableBox'
import Card from '@/components/partials/Card'
import BoxSearch from '@/components/partials/forms/BoxSearchCommon'
import FormatDate from '@/components/partials/FormatDate'
import { PER_PAGE_LIST } from '@/config/constants'
import FormatNumber from '@/components/partials/FormatNumberic'
export default {
  name: 'pluginList',

  components: {
    FormatNumber,
    BoxSearch,
    Card,
    TableBox,
    Button,
    Main,
    FormatDate
  },
  data () {
    return {
      loading: false,
      dataList: [],
      isReload: false,
      index: 0,
      config: {
        q: '',
        action: 'plugin/getListPlugin',
        headers: [
          {
            label: 'pluginManagement.pluginCode'
          },
          {
            label: 'pluginManagement.name'
          },
          {
            label: 'pluginManagement.developer'
          },
          {
            label: 'pluginManagement.lastVersion'
          },
          {
            label: 'pluginManagement.pricePerMonth'
          },
          {
            label: ''
          }
        ],
        value: [],
        filters: [
          {
            field: 'selling_unit',
            label: 'pageMaterialUnit.field',
            data: []
          }
        ],
        sort: {
          selected: 'id',
          order: 'asc'
        }
      },
      dataFilters: {},
      page: 1,
      limit: PER_PAGE_LIST[0]
    }
  },
  head: {
    title: function () {
      return {
        inner: this.$t('menu.plugin.list')
      }
    }
  },
  methods: {
    onCheckDisabled (plugin) {
      let result = false
      if (plugin && plugin['RegisterInfos']) {
        result = plugin['RegisterInfos'].length > 0
      }
      return result
    },
    search (e) {
    },
    goToEdit (path, id) {
      this.$router.push({ path: path, query: { id: id } })
    },
    goPath (path) {
      if (path) {
        this.$router.push(path)
      }
    },
    deleteObject (e, row) {
      if (!this.onCheckDisabled(row)) {
        e.stopPropagation()
        let confirmCallback = () => {
          this.loading = true
          let successCallback = (response) => {
            this.loading = false
            this.$notify({
              title: this.$t('msg.titleSuccess'),
              message: this.$t('pluginManagement.msgDeleteSuccess'),
              type: 'success'
            })
            this.isReload = !this.isReload
          }
          let errorCallback = (error) => {
            this.loading = false
            if (error.response.status === 403) {
              this.$notify({
                title: this.$t('msg.titleError'),
                message: this.$t('pluginManagement.msgDeleteError'),
                type: 'error'
              })
            }
            this.isReload = !this.isReload
          }
          let payload = {
            successCallback,
            errorCallback,
            id: row.id
          }
          this.$store.dispatch('plugin/deletePlugin', payload)
        }
        let payloadConfirm = {
          callback: confirmCallback,
          message: this.$t('pluginManagement.msgConfirmDelete')
        }

        this.$store.commit('openConfirm', payloadConfirm)
      }
    }
  },
  watch: {
    config: {
      deep: true,
      handler: function () {
        this.index++
      }
    }
  }
}
</script>
