<template>
  <div>
    <div >
      <el-row >
        <el-col  :xl="8" :lg="6" :xs="24" :sm="6">
          <div class="pagination__items d-flex align-items-center mb-2 mb-sm-0">
            <span class="pagination__items__label mr-2">
              {{ $t('paginator.display') }}
            </span>
            <el-select
                v-model="model.limit"
                @change="updateLimit"
                class="select-limit">
              <el-option
                  v-for="value in perPageList"
                  :key="value"
                  :label="value"
                  :value="value">
              </el-option>
            </el-select>
            <span class="pagination__items__label ml-2">
              {{$t('paginator.perPage')}}
            </span>
          </div>
        </el-col>
        <el-col  :xl="16" :lg="18" :xs="24" :sm="18">
          <div class="d-flex justify-content-end align-items-center">
            <slot name="searchBox"></slot>
          </div>
        </el-col>
        <el-col :span="24">
          <slot name="filterBox"></slot>
        </el-col>
      </el-row>
      <div class="table-responsive table-box__common">
        <table class="table custom-table" id="basicExample">
          <thead>
          <template v-if="config.headers">
            <th
                v-for="(item, k) in config.headers"
                :key="k"
                @click="handleAction(item)"
                :class="{ 'has-action': item.sort || item.callback, 'sort-active': item.sort === model.sort.field }">
              <div  class="d-flex  align-items-center th-sort">
                {{ $t(item.label) }}
                <template v-if="item.sort">
                  <span class="sort-item ml-1">
                      <i class="el-icon-caret-top"
                         :class="{'sort-active':item.sort === model.sort.field && model.sort.order === 'asc'}"></i>
                      <i class="el-icon-caret-bottom"
                         :class="{'sort-active':item.sort === model.sort.field && model.sort.order === 'desc'}"></i>
                  </span>
                </template>
              </div>
            </th>
          </template>

          <slot v-else name="head"></slot>
          </thead>
          <tbody v-if="isLoading">
          <tr>
            <td
                class="text-center pt-5 row-loading"
                :colspan="config.headers ? config.headers.length : 2 ">
              <el-icon class="el-icon-loading row-loading__icon" style="font-size: 32px" ></el-icon>
              <div class="row-loading__text">
                {{$t('textCommon.loading')}}
              </div>
            </td>
          </tr>
          </tbody>
          <tbody v-else>
          <template v-for="(row, index) in dataList">
            <slot
                name="row"
                :row="row"
                :index="index"
                :page="model.page"
                :perPage="model.limit">
            </slot>
          </template>
          <tr v-if="dataList && dataList.length ===0 || !dataList">
            <td
                class="text-center py-2 border-0"
                :colspan="config.headers ? config.headers.length : 2 ">
              <el-empty
                  :description="textNodata"
                  :image-size="300">
              </el-empty>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="table-box__footer">
        <div class="d-flex justify-content-start  align-items-center justify-content-sm-between flex-column flex-sm-row">
          <div class="text-pages" v-if="total">
            {{ $t('paginator.showInfo', {fieldTo:to , fieldFrom:  from, fieldTotal: total}) }}
          </div>
          <Pagination
              v-if="total"
              :total="total"
              :page.sync="model.page"
              :per-page="model.limit*1"
              @updatePerPage="updatePerPage">
          </Pagination>
        </div>

      </div>
    </div>

  </div>

</template>

<script>
import {
  PER_PAGE_LIST,
  FILTER_SAVE,
  TIMING_LOADER
} from '../../config/constants'
import { formatDateUTC0 } from '@/utils'
import Pagination from './Pagination'

export default {
  name: 'TableBox',
  comments: {
    formatDateUTC0
  },
  components: {
    Pagination
  },
  props: {
    height: {
      type: String,
      default: '500px'
    },
    fieldTableName: {
      type: String,
      required: false,
      default: ''
    },
    dataList: {
      type: Array,
      require: true
    },
    config: {
      type: Object,
      require: true
    },
    reload: {
      require: false
    },
    isFilterDomain: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    'config.q': function (val) {
      this.model.search = val
      this.model.page = 1
    },
    model: {
      deep: true,
      handler: function () {
        clearTimeout(this.retrieveListTimeout)
        this.retrieveListTimeout = setTimeout(() => {
          this.retrieveList()
        }, 500)
      }
    },
    reload: function () {
      this.retrieveList()
    }
  },
  data () {
    let data = {
      perPageList: PER_PAGE_LIST,
      total: 0,
      isLoading: false,
      textNodata: '',
      checkLoadingOnce: false,
      retrieveListTimeout: null,
      dataFilter: [],
      dataFilters: {},
      model: {
        page: 1,
        dataFilter: {},
        limit: PER_PAGE_LIST[0],
        filters: {},
        search: '',
        sort: {
          field: 'created_at',
          order: 'desc'
        }
      }
    }
    data.model.dataFilter = this.config.dataFilter
    data.model.search = this.config.q
    let dataFilter = this.$cookies.get(FILTER_SAVE)
    if (!this.checkLoadingOnce && dataFilter && dataFilter[this.config.action]) {
      data.model = Object.assign(dataFilter[this.config.action])
      this.$emit('updateModel', data.model)
    }
    return data
  },
  computed: {
    from: function () {
      return (this.model.page - 1) * this.model.limit + 1
    },
    to: function () {
      let to = this.model.page * this.model.limit
      return to > this.total ? this.total : to
    },
    isSearch () {
      let result = false
      if (Object.keys(this.config.dataFilter).length > 0) {
        Object.keys(this.config.dataFilter).map(elm => {
          if (elm && this.config.dataFilter[elm]) {
            result = true
          }
        })
      } else {
        result = false
      }
      return result
    }
  },
  mounted () {
    this.retrieveList()
  },
  methods: {
    onChange () {
      this.retrieveList()
    },
    handleAction (header) {
      if (header.sort) {
        this.handleSort(header.sort)
      } else if (header.callback && typeof header.callback === 'function') {
        header.callback()
      }
    },
    handleSort (field) {
      let originOrder = this.model.sort.field === field ? this.model.sort.order : ''
      let order
      switch (originOrder) {
        case 'asc':
          order = 'desc'
          break
        case 'desc':
          order = field === 'created_at' ? 'asc' : ''
          field = ''
          break
        default:
          order = 'asc'
      }
      this.model.sort.field = !field ? 'created_at' : field
      this.model.page = 1
      this.model.sort.order = order || 'desc'
      this.model.sort = Object.assign({}, this.model.sort)
      this.model = Object.assign({}, this.model)
    },
    retrieveList: function () {
      if (this.isLoading || !this.config.action) return
      this.isLoading = true
      if (this.checkLoadingOnce) {
        let dataFilter = this.$cookies.get(FILTER_SAVE) || {}
        dataFilter[this.config.action] = Object.assign({}, this.model)
        this.$cookies.set(FILTER_SAVE, dataFilter, '0')
      }

      let payload = {
        successCallback: (response) => {
          let { data } = response || []
          this.$emit('update:data-list', data.data)
          this.total = data.total
          this.model.page = Number(data.page)
          this.model.limit = Number(data.page_size)
          let isData = data.data ? data.data.length : false
          if (!isData && this.model.page !== 1) {
            this.model.page = this.model.page - 1 || 1
          }

          this.checkLoadingOnce = true
          this.$emit('page', this.model.page)
          setTimeout(() => {
            this.isLoading = false
          }, TIMING_LOADER)
          this.textNodata = this.model.search || this.isSearch ? this.$t(`${this.fieldTableName}.msgNoDataSearch`) : this.$t(`${this.fieldTableName}.msgNoData`)
        },
        errorCallback: () => {
          this.textNodata = this.model.search || this.isSearch ? this.$t(`${this.fieldTableName}.msgNoDataSearch`) : this.textNodata = this.$t(`${this.fieldTableName}.msgNoData`)
          this.isLoading = false
          this.$emit('update:data-list', [])
          this.total = 0
        },
        query: this.getQueryString(),
        id: this.$route.query.id
      }
      this.$store.dispatch(this.config.action, payload)
    },
    getQueryString () {
      let { model, config } = this
      model.search = this.config.q
      let query = [`page=${model.page}`, `page_size=${model.limit}`]
      // if (model.sort.field) {
      //   if (this.config.action !== ACTION_LIST_CUSTOMER) {
      //     query.push(`sort[${model.sort.field}]=${model.sort.order}`)
      //   } else {
      //     query.push(`sort={"${model.sort.field}":${model.sort.order === 'desc' ? -1 : 1}}`)
      //   }
      // }
      if (model.search) {
        query.push(`search=${model.search}`)
      }
      if (config.dataFilter) {
        for (const [key, value] of Object.entries(config.dataFilter)) {
          if (key && (value !== null && value !== '')) {
            if (key === 'id_domain') {
              if (value && value.length > 0) {
                let valueCustom = value.join()
                query.push(`filters[${key}]=${valueCustom}`)
              } else {
                if (this.isFilterDomain) {
                  query.push(`filters[${key}]=-4`)
                } else {
                  query.push(`filters[${key}]=`)
                }
              }
            } else {
              query.push(`filters[${key}]=${value}`)
            }
          }
        }
      }
      return query.join('&')
    },
    updatePerPage (value) {
      this.model.limit = value
      this.model.page = 1
    },
    updateLimit (value) {
      this.model.page = 1
    }
  }
}
</script>

<style lang="scss">
.pagination__items__label{
  font-weight: 500;
  font-size: 14px;
  padding-top: 5px;
}
.text-no-data{
  text-transform: lowercase;
  color: rgba(123, 140, 157, .9);
  &:first-letter{
    text-transform: uppercase;
  }
}
.text-pages{
  padding: 0.5em 1.5em;
  display: inline-block;
  font-size: .825rem;
  background-color: #DED46E;
  margin-top: 10px;
  border-radius: 2px;
  font-weight: 500;
  letter-spacing: 1.5px;
  @include Responsive(XS){
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
  }
}
.filter {
  margin-left: 10px;
  .el-button {
    background: #FFFFFF;
    border: 1px solid #dedcfb;
    border-radius:  2px !important;
    color: #7987a1;
    font-weight: 400;
  }
  .el-button:hover, .el-button:active, .el-button:focus, .el-button::selection {
    background: #FFFFFF;
    color: #7987a1;
  }
}
.only-page {
  .el-checkbox-group {
    .el-checkbox {
      width: 100%;
      overflow: hidden;
    }
  }
}
.table-box__common{
  background: $white-color;
  .table{
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    .sort-item{
      cursor: pointer;
      i{
        opacity: .4;
        line-height: .9 !important;
        font-weight: bold;
        font-size: 9.5px !important;
      }
      .sort-active{
        opacity: 1;
      }
    }
    thead{
      th{
        background: #DED46E;
        color: #fff;
        font-weight: 500;
        letter-spacing: 2px;
        border:none;
        padding:0px 3px !important;
        border-bottom: 1px solid #e1e7ed!important;
        &:first-child{
          border-radius: 4px 0px 0px 0px;
        }
        &:last-child{
          border-radius: 0px 4px 0px 0px;
        }
        .th-sort{
          padding: 0.5rem 0.5rem 0.5rem 0.5rem !important;
        }
      }
    }
    tbody{
      .row-header{
        background: #1173eb !important;
        color: #ffffff;
        font-weight: 600;
        border:none;
        th{
          color:#FFFFFF;
          border:none !important;
        }
      }
      tr{
        border-color: inherit;
        border-style: solid;
        border-width: 0;
        &:nth-child(even){
          background: #f6f6fd;
        }
        &:nth-child(odd){
          background: #ffffff;
        }
      }
      td{
        border: 0;
        padding: 0.75rem 0.5rem !important;
        white-space: normal;
        vertical-align: middle;
        word-break: normal;
        border-bottom: 1px solid #e1e7ed !important;
        &.border-0{
          border: none !important;
        }
        .media-box{
          display: flex;
          align-items: center;
          .media-avatar {
            height: 40px;
            width: 40px;
            margin: 0 10px 0 0;
          }
          .media-box-body {
            flex: 1;
            a {
              font-size: .75rem;
              font-weight: 600;
              margin: 0 0 5px 0;
            }
          }
        }
        .row-loading{
          &__icon{
            color: #978c1f;
          }
          &__text{
            color: #000000;
          }
        }
      }
    }

  }
}

.select-limit{
  min-height: 30px;
  width: 75px;
  .el-input{
    min-height: 32px;
    input{
      min-height: 32px;
      height: 36px !important;
      font-size: 14px;
      border-radius: 2px;
    }
  }
  .el-input__icon{
    line-height: 36px;
  }
}
.sort-item{
  display: flex;
  justify-items: center;
  flex-direction: column;
}
.el-dropdown-menu{
  &.el-popper{
    .el-dropdown-menu__item{
      padding: 0px;
      a{
        padding: 0px 20px;
      }
    }
  }

}
.w-sm-100{
  @include Responsive(XS){
    width: 100% !important;
  }
}

</style>
