import ListPlugins from '@/views/pluginManagement/index'
import EditPlugins from '@/views/pluginManagement/edit'
import { APP_TOKEN_NAME } from '@/config/constants'
const ifAuthenticated = (to, from, next) => {
  let token = window.$cookies.get(APP_TOKEN_NAME)
  if (to.path !== '/login' && token) {
    next()
  } else {
    next({ name: 'login' })
  }
}
export default [
  {
    path: '/plugins',
    name: 'pluginsManagement',
    component: ListPlugins,
    meta: {
      permission: []
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/plugins/edit',
    name: 'editPlugin',
    component: EditPlugins,
    meta: {
      permission: []
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/plugins/add',
    name: 'addPlugin',
    component: EditPlugins,
    meta: {
      permission: []
    },
    beforeEnter: ifAuthenticated
  }
]
