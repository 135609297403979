import { GET_CUSTOMER_BY_ID, GET_LIST_CUSTOMER, CHANGE_STATUS_CUSTOMER, CREATE_CUSTOMER, DELETE_CUSTOMER, UPDATE_CUSTOMER, GET_ALL_LIST_CUSTOMER, IMPORT_CUSTOMER } from '@/api/admin/customer'

export default {
  getListCustomer ({ commit }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    GET_LIST_CUSTOMER({ payload }, successCallback, errorCallback)
  },

  getCustomerById ({ commit }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    GET_CUSTOMER_BY_ID({ payload }, successCallback, errorCallback)
  },

  createCustomer ({ commit }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    CREATE_CUSTOMER({ payload }, successCallback, errorCallback)
  },

  updateCustomer ({ commit }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    UPDATE_CUSTOMER({ payload }, successCallback, errorCallback)
  },

  deleteCustomer ({ commit }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    DELETE_CUSTOMER({ payload }, successCallback, errorCallback)
  },

  changeStatusCustomer ({ commit }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    CHANGE_STATUS_CUSTOMER({ payload }, successCallback, errorCallback)
  },

  getAllCustomer ({ commit }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    GET_ALL_LIST_CUSTOMER({ payload }, successCallback, errorCallback)
  },

  importCustomer ({ commit }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    IMPORT_CUSTOMER({ payload }, successCallback, errorCallback)
  }
}
