<template>
    <button
        type="button"
        :class="`btn-${type} ${classes}`"
        class="btn"
        :disabled="disabled"
        @click="handleClick">
        <slot></slot>
    </button>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Button',
  props: {
    disabled: Boolean,
    type: String,
    classes: {
      type: String,
      default () {
        return ''
      }
    }
  },
  methods: {
    handleClick () {
      this.$emit('click')
    }
  },
  computed: {
    ...mapState(['changeColor'])
  },
  mounted () {
    this.button = document.querySelectorAll('button.btn-primary')
    if (this.button) {
      for (let item in this.button) {
        if (this.button[item] && this.button[item].style) {
          this.button[item].style.backgroundColor = this.changeColor
        }
      }
    }
  }
}
</script>

<style lang="scss">
    .btn {
        @include bold-font(13px);
        @include transition(all, 0.3s, ease-out);
        line-height: 1.538;
        padding: 9px 20px;
        $button: &;
        border: 0;
        border-radius: 3px;
        text-align: center;
        vertical-align: middle;
        display: inline-block;
        white-space: nowrap;
        border-width: 2px !important;
        @include Responsive(XS){
          padding: 6px 12px !important;
        }
        &-small{
          padding: 2px 14px !important;
        }
        &.btn-block{
            display: block;
            width: 100%;
        }
        &-default{

        }
        &-primary{
            color: #fff;
            background-color: #3858f9;
            border-color: #3858f9;
            &:hover{
                color: #fff;
                background-color: #0d49ff;
                border-color: #0040ff;
            }
        }
        &-danger{
            color: #fff;
            background-color: #f53c5b !important;
            border-color: #f53c5b;
            &:hover{
                color: #fff;
                background-color: rgba(232, 52, 82,.8) !important;
                border-color: #e83452;
            }
        }
        &-success{
            color: #fff;
            background-color: #0ba360 !important;
            border-color: #0ba360;
            &:hover{
                color: #fff;
                background-color: #0e965a;
                border-color: #0e965a;
            }
        }
        &-warning{
            color: #2a283c ;
            background-color: #ffc107 !important;
            border-color: #ffc107;
            &:hover{
                color: #2a283c;
                background-color: #e0a800;
                border-color: #d39e00;
            }
        }

    }
</style>
