<template>
  <div class="non-siderbar">
    <div class="login-container">
      <div class="container-fluid h-100">
        <div class="row g-0 h-100">
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="login-wrapper">
              <form>
                <div class="login-screen">
                  <div class="login-body mb-5">
                    <a href="/" class="login-logo">
                      <img src="@/assets/images/app_icon_default.png" alt="admin-plugin-logo">
                      <span>Plugin Admin</span>
                    </a>
                    <h6>Welcome back,<br>Forgot password</h6>
                    <div class="field-wrapper">
                      <input v-model="email" type="email" class="form-control" :class="{'form-error':errorInfo.email}" >
                      <div class="field-placeholder">{{$t('pageLogin.email')}} <span class="text-danger">*</span></div>
                      <div class="invalid-feedback" :class="{'d-block': errorInfo.email }" v-if="errorInfo.email">{{errorInfo.email}}</div>
                    </div>
                    <div class="actions">
                      <a href="javascript:void(0)" @click="path('/login')">  {{$t('admin.login.backToLogin')}}</a>
                      <button type="button" @click="getSecureCode" class="btn btn-primary"> {{$t('button.send')}}</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import { validEmail } from '@/utils'
export default {
  name: 'login',
  comments: { validEmail },
  data () {
    return {
      email: '',
      checkRemember: false,
      isLoading: false,
      errorInfo: {
        email: ''
      }
    }
  },
  head: {
    title: function () {
      return {
        inner: this.$t('menu.forgotPassword')
      }
    }
  },
  methods: {
    enterKeyboard: function (e) {
      if (e.keyCode === 13) {
        this.getSecureCode()
      }
    },
    path (path) {
      this.$router.push({ path: path })
    },
    getSecureCode () {
      let check = this.validate()
      if (check) {
        this.isLoading = true
        let successCallback = (response) => {
          if (response.status === 201) {
            this.$message({
              showClose: true,
              message: this.$t('admin.forgot.sendForgotSuccess'),
              type: 'success'
            })
            setTimeout(() => {
              this.isLoading = false
              this.$router.push({ path: '/reset-password' })
            }, 1000)
          }
        }
        let errorCallback = () => {
          this.$message({
            showClose: true,
            message: this.$t('admin.forgot.msgEmailNoRegister'),
            type: 'error'
          })
          this.isLoading = false
        }
        let payload = {
          data: {
            email: this.email
          },
          successCallback,
          errorCallback
        }
        this.$store.dispatch('auth/forgotPassword', payload)
      }
    },
    validate (field) {
      let check = true
      if ((field === 'email' || !field)) {
        if (this.email === '' || !this.email) {
          this.errorInfo.email = this.$t('admin.form.emptyEmail')
          check = false
        } else if (this.email && !validEmail(this.email)) {
          this.errorInfo.email = this.$t('admin.form.errorMailFormat')
          check = false
        } else {
          this.errorInfo.email = ''
        }
      }
      return check
    }
  }
}
</script>
