<template>
  <div :class="`${prevText ? 'input-group ': ''} custom-input ${disabled? ' disabled': ''} ${classes}`">
  <input
      :type="type"
      :placeholder="placeholder"
      v-bind="$attrs"
      :readonly="readonly"
      :disabled="disabled"
      v-on="inputListeners"
      class="form-control"
      :class="{'is-invalid':error}"
      :value="value"
      :rows="rows"
      @input="$emit('input', $event.target.value)">
    <span class="input-group-text" v-if="prevText">
      {{prevText}}
    </span>
  </div>
</template>
<script>

export default {
  name: 'custom-input',
  inheritAttrs: false,
  props: {
    value: [String, Number],
    error: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    classes: {
      type: String,
      required: false,
      default: ''
    },
    rows: {
      type: Number,
      required: false,
      default: 2
    },
    placeholder: {
      type: String,
      required: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    prevText: {
      default: '',
      type: String,
      required: false

    }
  },
  data () {
    return {
      rows1: this.rows || 1,
      value1: '',
      isFocused: false,
      message: null,
      maxLength: null
    }
  },
  computed: {
    listeners () {
      return {
        ...this.$listeners,
        blur: this.onBlur,
        focus: this.onFocus
      }
    },
    mergedProps () {
      return {
        ...this.$attrs,
        ...this.$props
      }
    },
    inputListeners: function () {
      let vm = this
      return Object.assign({},
        this.$listeners,
        {
          input: function (event) {
            vm.$emit('input', event.target.value)
          }
        }
      )
    }
  },
  methods: {
    changeInput (e) {
      if (e.target.value.length <= this.maxLength) {
        this.$emit('input', e.target.value)
      } else {
        e.target.value = this.value
        this.$emit('input', this.value)
      }
    },
    onBlur () {
      this.isFocused = false
    },
    onFocus () {
      this.isFocused = true
    }
  }
}
</script>
<style lang="scss">
.el-textarea{
  border-radius: 2px;
}
.custom-input {
  .form-control{
    padding-left: 12px;
    &:disabled{
      pointer-events: none;
    }
    &:read-only{
      pointer-events: none;
    }
  }
  &.is-disabled{
    cursor: not-allowed
  }
}
</style>
