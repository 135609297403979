import { AUTH_LOGIN, LOGOUT, FORGOT_PASSWORD, CHANGE_PASSWORD, GET_MY_INFO, REFRESH_TOKEN } from '@/api/admin/auths'

export default {
  login ({ commit, state }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    AUTH_LOGIN({ payload }, successCallback, errorCallback)
  },

  logOut ({ commit, state }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    LOGOUT({ payload }, successCallback, errorCallback)
  },

  forgotPassword ({ commit, state }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    FORGOT_PASSWORD({ payload }, successCallback, errorCallback)
  },

  resetPassword ({ commit, state }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    CHANGE_PASSWORD({ payload }, successCallback, errorCallback)
  },

  getMyInfo ({ commit, state }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    GET_MY_INFO({ payload }, successCallback, errorCallback)
  },

  refreshToken ({ commit, state }, payload) {
    let errorCallback = (error) => {
      if (typeof payload.errorCallback === 'function') {
        payload.errorCallback(error)
      }
    }
    let successCallback = (response) => {
      if (typeof payload.successCallback === 'function') {
        payload.successCallback(response)
      }
    }
    REFRESH_TOKEN({ payload }, successCallback, errorCallback)
  }

}
