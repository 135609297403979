<template>
  <Main>
    <template #body>
      <Card>
        <template #body>
         <el-row>
           <el-col :span="16">
             <h3>Settings</h3>
           </el-col>
         </el-row>
        </template>
      </Card>
    </template>
  </Main>
</template>
<script>
import Main from '@/components/partials/layout/Main'
import Card from '@/components/partials/Card'

export default {
  data () {
    return {
      currentLanguage: ''
    }
  },
  components: { Card, Main },
  head: {
    title: function () {
      return {
        inner: this.$t('menu.staffManagement')
      }
    }
  },
  created () {
    this.$store.commit('setBreadCrumb', [{ label: 'settings' }])
    this.currentLanguage = this.$i18n.locale
  },
  methods: {
    handleChangeLanguage () {
      this.$i18n.locale = this.currentLanguage
    }
  }
}
</script>
