<template>
  <Main class="">
    <template #body>
      <div class="user-page__header mb-3">
        <div class="d-flex justify-content-end">
            <Button
                type="primary"
                @click="goPath('/customer/add')">
              <i class="el-icon-plus"></i>
              {{$t('button.addMore')}}
            </Button>
        </div>
      </div>
      <Card>
        <template #header>
          {{$t('menu.customer.list')}}
        </template>
        <template #body>
          <TableBox
              fieldTableName="planManagement"
              :data-list.sync="dataList"
              :config="config"
              :reload="isReload"
              @updateModel="setConfig($event)">
            <template #searchBox>
             <BoxSearch placeholder="Search" v-model="config.q"></BoxSearch>
            </template>
            <template #row="{ row }">
              <tr>
                <td class="text-center" style="max-width: 80px; width: 80px">
                  <el-avatar :size="60" src="">
                    <img v-if="row.avatar" :src="row.avatar" alt="Avatar">
                  </el-avatar>
                </td>
                <td style="width: 250px">
                  <div class="td-avatar">
                    {{row.code}}
                  </div>
                </td>
                <td>
                  <div>{{row.full_name}}</div>
                  <div>({{row.address}})</div>
                </td>
                <td>
                  <p class="note-customer">{{row.email}}</p>
                </td>
                <td>{{row.phone}}</td>
                <td>
                  <FormatDate format="Y/MM/DD (ddd)" :date="row.create_date"/>
                </td>
                <td>
                  {{row.status ? $t('button.enable') : $t('button.disable')}}
                </td>
                <td>
                  {{row.listDomain ? row.listDomain.length : 0 }}
                  <div v-for="domain in row.listDomain" :key="domain.ID">
                    {{domain.domain}}
                  </div>
                </td>
                <td class="text-center column-action" width="8%">
                  <a
                      href="javascript:void(0)"
                      :title="$t('button.edit')"
                      @click="goToEdit('/customer/edit', row.id)">
                    <i class="el-icon-edit-outline"></i>
                  </a>
                  <a
                      class="text-danger"
                      :title="$t('button.delete')"
                      :class="{'icon-disabled': onCheckDisabled(row)}"
                      href="javascript:void(0)"
                     @click="deleteObject($event, row)">
                    <i class="el-icon-circle-close"></i>
                  </a>

                </td>
              </tr>
            </template>

          </TableBox>

        </template>
      </Card>
    </template>
  </Main>
</template>

<script>
import Main from '@/components/partials/layout/Main'
import Button from '@/components/partials/Button'
import TableBox from '@/components/partials/TableBox'
import Card from '@/components/partials/Card'
import BoxSearch from '@/components/partials/forms/BoxSearchCommon'
import FormatDate from '@/components/partials/FormatDate'
import { PER_PAGE_LIST } from '@/config/constants'
export default {
  name: 'customerList',

  components: {
    FormatDate,
    BoxSearch,
    Card,
    TableBox,
    Button,
    Main
  },
  data () {
    return {
      loading: false,
      dataList: [],
      isReload: false,
      keySearch: '',
      config: {
        q: '',
        action: 'customer/getListCustomer',
        headers: [
          {
            label: 'staffManagement.image'
          },
          {
            label: 'customerManagement.code'
          },
          {
            label: 'customerManagement.name'
          },
          {
            label: 'customerManagement.email'
          },
          {
            label: 'customerManagement.phone'
          },
          {
            label: 'customerManagement.createdAt'
          },
          {
            label: 'customerManagement.status'
          },
          {
            label: 'customerManagement.domainRegister'
          },
          {
            label: ''
          }
        ],
        value: [1, 0],
        dataFilter: {
          status: '',
          packageId: '',
          created_at: ''
        },
        sort: {
          selected: 'id',
          order: 'asc'
        }
      },
      dataFilters: {},
      page: 1,
      limit: PER_PAGE_LIST[0]
    }
  },
  head: {
    title: function () {
      return {
        inner: this.$t('menu.customer.list')
      }
    }
  },
  methods: {
    setConfig (e) {
      this.keySearch = e.search
      this.config.q = e.search
      this.config = Object.assign({}, this.config)
    },
    onCheckDisabled (user) {
      let result = true
      if (user && user.listDomain) {
        result = user.listDomain.filter(elm => elm['RegisterInfos'] && elm['RegisterInfos'].length > 0).length > 0
      }
      return result
    },
    changeStatus (e, row) {
      e.stopPropagation()
      let confirmCallback = () => {
        this.loading = true
        let successCallback = (response) => {
          this.loading = false
          this.$notify({
            title: this.$t('msg.titleSuccess'),
            message: this.$t('msg.descUpdateSuccess', { field: this.$t('customerManagement.field') }),
            type: 'success'
          })
        }
        let errorCallback = (error) => {
          this.loading = false
          this.$notify({
            title: this.$t('msg.titleError'),
            message: error.response.data.message,
            type: 'error'
          })
        }
        let payload = {
          successCallback,
          errorCallback,
          id: row.id,
          data: {
            updated_at: row.updated_at
          }
        }
        this.$store.dispatch('customer/changeStatus', payload)
      }
      let payloadConfirm = {
        callback: confirmCallback,
        message: this.$t('msg.changeStatusConfirm', { field: this.$t('customerManagement.field') })
      }
      this.$store.commit('openConfirm', payloadConfirm)
    },
    goToEdit (path, id) {
      let historyEdit = localStorage.getItem('historyQuery')
      localStorage.setItem('historyQueryEdit', historyEdit)
      this.$router.push({ path: path, query: { id: id, t: new Date().getTime() } })
    },
    goPath (path) {
      if (path) {
        this.$router.push(path)
      }
    },
    deleteObject (e, row) {
      if (!this.onCheckDisabled(row)) {
        e.stopPropagation()
        let confirmCallback = () => {
          this.loading = true
          let successCallback = (response) => {
            this.loading = false
            this.$notify({
              title: this.$t('msg.titleSuccess'),
              message: this.$t('customerManagement.msgDeleteSuccess'),
              type: 'success'
            })
            this.isReload = !this.isReload
          }
          let errorCallback = (error) => {
            this.loading = false
            if (error.response.status === 403) {
              this.$notify({
                title: this.$t('msg.titleError'),
                message: this.$t('customerManagement.msgDeleteErrorUser'),
                type: 'error'
              })
            }
            this.isReload = !this.isReload
          }
          let payload = {
            successCallback,
            errorCallback,
            id: row.id
          }
          this.$store.dispatch('customer/deleteCustomer', payload)
        }
        let payloadConfirm = {
          callback: confirmCallback,
          message: this.$t('customerManagement.msgConfirmDelete', { field: this.$t('customerManagement.field') })
        }

        this.$store.commit('openConfirm', payloadConfirm)
      }
    }

  },

  watch: {
    config: {
      deep: true,
      handler: function () {
      }
    }
  }
}
</script>
