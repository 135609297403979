export default {
  auth_request (state) {
    state.status = 'loading'
  },
  auth_success (state, payload) {
    state.status = 'success'
    state.userInfo = payload
  },
  auth_error (state) {
    state.status = 'error'
    state.token = ''
    state.userInfo = null
  },
  logout (state) {
    state.status = ''
    state.token = ''
  },
  auth_loading (state, loading) {
    state.loading = loading
  }
}
