export default {
  notificationData: {
    show: false,
    message: '',
    type: ''
  },
  confirm: {
    show: false,
    message: '',
    title: '',
    callback: null
  },
  confirmNotification: {
    show: false,
    message: '',
    title: ''
  },
  loading: {
    count: 0
  },
  settingAppConfig: null,
  socket: {},
  firebaseToken: null,
  currentMenu: '',
  currentPath: '',
  breadCrumb: [],
  codeError: null,
  refreshToken: null,
  isShowModalMap: false
}
