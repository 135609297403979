<template>
  <nav class="sidebar-wrapper">
    <div class="sidebar-tabs">
      <div
          class="nav"
          role="tablist"
          aria-orientation="vertical">
        <a href="javascript:void(0)" class="logo" @click="goToMain">
          <img
              src="@/assets/images/app_icon_default.png"
              alt="Plugins Kintone Management">
        </a>
        <a v-for="(item, k) in MENUS" :key="k"
           class="nav-link" style="cursor: pointer"
           :class="checkActiveMenu(k, item) ? 'active' : ''"
           :id="'tab-'+k" data-bs-toggle="tab" :title="$t(`menu.${item.label}`)"
           role="tab"
           :aria-controls="item.label" @click="goPath(item.subMenu[0].path)"
           aria-selected="true">
          <i :class="item.icon"></i>
        </a>
      </div>
      <div class="tab-content" v-if="onCheckActive">
        <div
            v-for="(item, k) in MENUS"
            :key="'tab'+k"
            class="tab-pane fade"
            :class="{'show active font-weight-bold': checkActiveMenu(k, item)}"
            :id="item.label"
            role="tabpanel"
            :aria-labelledby="'tab-'+k">
          <div class="tab-pane-header">
            {{ $t(`menu.${item.label}`) }}
          </div>
          <div class="sidebarMenuScroll">
            <div class="sidebar-menu">
              <ul>
                <li v-for="(subMenu, subK) in item.subMenu" :key="'sub'+subK">
                  <a
                      @click="goPath(subMenu.path)"
                      :class="checkActiveSubMenu(subK, subMenu) ? 'current-page' : ''">
                    {{ $t('menu.'+ subMenu.label) }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

  </nav>
</template>

<script>
import { mapState } from 'vuex'
import { APP_TOKEN_NAME, USER_INFO_KEY } from '@/config/constants'
import MENUS from '@/config/menuSiderBar'
import { generateStorageKey } from '@/utils'
import { LANGUAGE_SETTING_APP } from '../../../config/constants'
import moment from 'moment'
export default {
  name: 'sideBarPartial',
  // components: { MenuItem },
  data: function () {
    return {
      menuActive: '',
      isShowMenu: false,
      language: window.$cookies.get(LANGUAGE_SETTING_APP) || 'ja',
      MENUS: MENUS,
      listLanguage: [
        {
          label: 'Tiếng Việt',
          key: 'vi'
        },
        {
          label: '日本語',
          key: 'ja'
        },
        {
          label: 'English',
          key: 'en'
        }

      ]
    }
  },
  created () {
  },
  mounted () {},
  methods: {
    goToMain () {
      let name = this.$route.name
      if (name !== 'dashboard') {
        this.$router.push({ name: 'dashboard' })
      }
    },
    goPath (path) {
      this.menuActive = path
      this.$router.push({ name: path })
    },
    checkActiveMenu (subIndex, subMenuItem) {
      return (subIndex === this.activeMenuIndex || JSON.stringify(this.menuActive) === JSON.stringify(subMenuItem.pathActive))
    },
    checkActiveSubMenu (subIndex, subMenuItem) {
      if (subMenuItem && subMenuItem.pathActive && subMenuItem.pathActive.length > 0) {
        let currentPath = this.$route.name
        let index = subMenuItem.pathActive.findIndex(el => el === currentPath)
        return index >= 0
      } else {
        return false
      }
    },
    setLanguage () {
      window.$cookies.set(LANGUAGE_SETTING_APP, this.language)
      window.$i18n.locale = this.language
      moment.locale(this.language)
    },
    showMenuLeft () {
      this.$store.commit('isShowMenuLeft', true)
    },
    goDashboard () {
      this.$router.push({ name: 'home' })
    },
    logOut () {
      let confirmCallback = () => {
        let successCallback = (response) => {
          this.$store.commit('auth/logout')
          this.$cookies.set(generateStorageKey(APP_TOKEN_NAME), '')
          this.$cookies.set(generateStorageKey(USER_INFO_KEY), '')
          this.$store.commit('auth/open_close_logout', false)
          this.$router.push({ name: 'login' })
        }
        let errorCallback = (error) => {
          this.$store.commit('addAlertItem', {
            type: 'error',
            content: error.response.data.messages[0]
          })
        }
        let payload = {
          successCallback,
          errorCallback
        }
        this.$store.dispatch('auth/logOut', payload)
      }
      let payloadConfirm = {
        callback: confirmCallback,
        message: this.$t('popup.confirmLogout')
      }
      this.$store.commit('openConfirm', payloadConfirm)
    }
  },
  computed: {
    ...mapState(['currentMenu', 'currentPath']),
    ...mapState('auth', ['userInfo']),
    activeMenuIndex () {
      let activeIndex = null
      if (MENUS) {
        for (let index in MENUS) {
          let menuItem = MENUS[index]
          if (Array.isArray(menuItem.pathActive) && menuItem.pathActive.length > 0) {
            let i = menuItem.pathActive.findIndex(x => x === this.currentPath)
            if (i >= 0) {
              activeIndex = index
            }
          }
        }
      }
      return parseInt(activeIndex)
    },
    activeSubMenuIndex () {
      let activeIndex
      if (this.MENUS) {
        for (let index in this.MENUS) {
          let menuItem = this.MENUS[index]
          if (Array.isArray(menuItem.pathActive) && menuItem.pathActive.length > 0) {
            let i = menuItem.pathActive.findIndex(x => x === this.currentPath)
            if (i >= 0) {
              activeIndex = i
            }
          }
        }
      }
      return parseInt(activeIndex)
    },
    onCheckActive () {
      let currentPath = this.$route.name
      let menu = null
      this.MENUS.map((elm, indexMEnu) => {
        let index = elm.pathActive.findIndex(elm => elm === currentPath)
        if (index >= 0) {
          menu = this.MENUS[indexMEnu]
        }
      })
      if (menu && (menu.subMenu && menu.subMenu.length > 1)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss">
.sidebar-menu{
  ul{
    li{
      cursor: pointer !important;
    }
  }
}
.app-sidebar {
  position: fixed;
  top: -19px;
  bottom: 0;
  left: 0;
  margin-top: 18px;
  width: 240px;
  overflow: auto;
  z-index: 999;
  transition: left 0.3s ease, width 0.3s ease;
  background: #fff;
  hr{
    border-top:1px dotted #ecedf2
  }
  @include compatible(box-shadow, 0px 8px 14.72px 1.28px rgba(159, 150, 226, 0.7));

  .main-sidebar {
    &-header {
      flex-shrink: 0;
      height: 64px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      background: #fff;
      border-bottom: 1px solid #dee4ec;
      border-right: 1px solid #dee4ec;
      position: fixed;
      width: 240px;
      z-index: 999;
      transition: left 0.3s ease, width 0.3s ease;
      .logo-light{
        img{
          width: 50px;
          margin-right: 10px;
          height: auto;
        }display: flex;
        align-items: center;
        color: #1e1d1d;
        font-size: 21px;
        font-weight: bold;
        span{
          position: relative;
          top: 2px;
        }
      }
    }

    &-loggedin {
      padding: 20px 20px 0 20px;
      display: flex;
      flex-shrink: 0;
      border-bottom: 0;
      margin-top: 64px;
    }
  }

  &__user {
    color: #a8a8a8;
    width: 100%;
    display: inline-block;
    text-align: center;

    .user-pic {
      margin: 0 auto 0.8rem;
      width: 60px;
      height: 60px;
      border-radius: 100%;
      overflow: hidden;
      @include compatible(box-shadow,  0px 5px 5px 0px rgba(44, 44, 44, 0.2));
      img {
        height: 100%;
      }
    }

    .user-info {
      .text-dark {
        color: #031b4e !important;
        font-weight: 600;
        font-size: 14px;
        margin-top: 13px;
      }

      .text-muted {
        color: #97a3b9 !important;
        font-size: 13px;
      }
    }

  }
}
.modal-backdrop{
  background-color: rgba(0, 0, 0, 0.3) !important;
}
</style>
