import Login from '@/views/auth/login'
import ForgotPassword from '@/views/auth/forgot-password'
import ResetPassword from '@/views/auth/reset-password'
export default [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      permission: []
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: {
      permission: []
    }
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword,
    meta: {
      permission: []
    }
  }
]
