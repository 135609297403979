<template>
  <Main class="material_list">
    <template #body>
      <div class="user-page__header mb-3">
        <div class="d-flex justify-content-end">
            <Button
                type="primary"
                @click="goPath('/register-plugin/add')">
              <i class="el-icon-plus"></i>
              {{$t('button.addMore')}}
            </Button>
        </div>
      </div>
      <Card>
        <template #header>{{$t('menu.registerInfo.list')}}
        </template>
        <template #body>
          <TableBox
              :fieldTableName="$t('registerInfo.field')"
              :data-list.sync="dataList"
              :config="config"
              :reload="isReload"
              @updateModel="setConfig($event)"
          :is-filter-domain="idCustomerFilter ? true : false">
            <template #filterBox>
              <div class="d-flex justify-content-end align-items-center mt-2">
                <CustomSelect placeholder="Customer" classes="small-height mr-4" v-model="idCustomerFilter" @change="handleReloadData">
                  <template #options>
                    <el-option
                        v-for="customer in listCustomer"
                        :key="`customer_${customer.id}`"
                        :value="customer.id"
                        :label="`[${customer.code}] ${customer.full_name}`"
                    ></el-option>
                  </template>
                </CustomSelect>
                <CustomSelect placeholder="Domain" classes="small-height small-height__max mr-4" is-multi collapseTags v-model="config.dataFilter.id_domain" @change="handleReloadData">
                  <template #options>
                    <el-option
                        v-for="domain in listDomain"
                        :key="`domain_${domain.ID}`"
                        :value="domain.ID"
                        :label="`${domain.domain}`">
                    </el-option>
                  </template>
                </CustomSelect>
                <CustomSelect placeholder="Plugin" classes="small-height" v-model="config.dataFilter.id_plugin" @change="handleReloadData">
                  <template #options>
                    <el-option
                        v-for="plugin in listPlugin"
                        :key="`plugin_${plugin.id}`"
                        :value="plugin.id"
                        :label="`[${plugin.code}] ${plugin.name}`"
                    ></el-option>
                  </template>
                </CustomSelect>
              </div>
            </template>
            <template #row="{ row }">
              <tr>
                <td>{{getDataField(row, 'customerName')}}</td>
                <td>{{getDataField(row, 'domain')}}</td>
                <td>{{getDataField(row, 'pluginName')}}</td>
                <td>
                  <FormatDate format="YYYY/MM/DD (ddd)" v-model="row.register_date"></FormatDate>
                </td>
                <td>
                  {{row.circle_rent}} {{$t('textCommon.month')}}
                </td>
                <td>
                  <FormatDate format="YYYY/MM/DD (ddd)" v-model="row.expired_date"></FormatDate>
                </td>
                <td>
                  <el-tag effect="dark" size="small" :type="checkColorStatus(row)">{{getDataField(row, 'status')}}</el-tag>
                </td>
                <td>
                  <FormatNumber :number="row.total_price_rent"></FormatNumber>
                </td>
                <td class="text-center column-action" width="8%" >
                  <a href="javascript:void(0)" :title="$t('button.edit')" @click="$router.push({path: '/register-plugin/edit', query: {id:row.id}})">
                    <i class="el-icon-edit-outline"></i>
                  </a>
                  <a class="text-danger" :title="$t('button.delete')" href="javascript:void(0)" @click="deleteObject($event,row)">
                    <i class="el-icon-circle-close"></i>
                  </a>
                </td>
              </tr>
            </template>

          </TableBox>

        </template>
      </Card>
    </template>
  </Main>
</template>

<script>
import Main from '@/components/partials/layout/Main'
import Button from '@/components/partials/Button'
import TableBox from '@/components/partials/TableBox'
import Card from '@/components/partials/Card'
import { PER_PAGE_LIST, LIST_STATUS_BILL } from '@/config/constants'
import FormatDate from '@/components/partials/FormatDate'
import FormatNumber from '@/components/partials/FormatNumberic'
import CustomSelect from '@/components/partials/forms/CustomSelect'
export default {
  name: 'materialList',

  components: {
    FormatNumber,
    FormatDate,
    Card,
    TableBox,
    Button,
    Main,
    CustomSelect
  },
  data () {
    return {
      loading: false,
      isReload: false,
      dataList: [],
      index: 0,
      config: {
        q: '',
        action: 'registerInfo/getListRegisterInfo',
        headers: [
          {
            label: 'registerInfo.customer'
          },
          {
            label: 'registerInfo.domainName'
          },
          {
            label: 'registerInfo.pluginName'
          },
          {
            label: 'registerInfo.registerDate'
          },
          {
            label: 'textCommon.circleRent'
          },
          {
            label: 'registerInfo.expiredDate'
          },
          {
            label: 'registerInfo.status'
          },
          {
            label: 'registerInfo.totalPriceRent'
          },
          {
            label: ''
          }
        ],
        value: [],
        dataFilter: {
          id_domain: '',
          id_plugin: ''
        },
        sort: {
          selected: 'id',
          order: 'asc'
        }
      },
      dataFilters: {},
      page: 1,
      limit: PER_PAGE_LIST[0],
      listCustomer: [],
      listPlugin: [],
      listDomain: [],
      idCustomerFilter: null
    }
  },
  head: {
    title: function () {
      return {
        inner: this.$t('menu.registerInfo.list')
      }
    }
  },
  created () {
    this.onGetAllPlugin()
    this.onGetAllCustomer()
  },
  methods: {
    handleReloadData () {
      this.isReload = !this.isReload
    },
    setConfig (e) {
      this.config.q = e.search
      if (e.dataFilter) {
        // this.config.dataFilter = Object.assign(e.dataFilter)
      }
      this.config = Object.assign({}, this.config)
    },
    onGetAllCustomer () {
      let successCallback = (response) => {
        if (response.status === 200) {
          this.listCustomer = response.data.data
        }
      }
      let errorCallback = () => {}
      let payload = {
        successCallback,
        errorCallback
      }
      this.$store.dispatch('customer/getAllCustomer', payload)
    },
    onGetAllPlugin () {
      let successCallback = (response) => {
        if (response.status === 200) {
          this.listPlugin = response.data.data
        }
      }
      let errorCallback = () => {}
      let payload = {
        successCallback,
        errorCallback
      }
      this.$store.dispatch('plugin/getAllListPlugin', payload)
    },

    getDataField (data, field) {
      let { AccountDomain, Plugin } = data
      let result = ''
      if (field === 'customerName') {
        if (AccountDomain && AccountDomain['Customer']) {
          result = AccountDomain['Customer']['full_name'] || ''
        }
      }
      if (field === 'domain') {
        if (AccountDomain) {
          result = AccountDomain['domain'] || ''
        }
      }
      if (field === 'pluginName') {
        if (Plugin) {
          result = Plugin['name'] || ''
        }
      }
      if (field === 'status') {
        let index = LIST_STATUS_BILL.findIndex(elm => elm.value === data.status)
        result = index >= 0 ? LIST_STATUS_BILL[index].label : ''
      }
      return result
    },
    checkColorStatus (row) {
      let status = row.status ? Number(row.status) : 1
      let color = 'primary'
      switch (status) {
        case 1:
          color = 'primary'
          break
        case 2:
          color = 'success'
          break
        case 3:
          color = 'warning'
          break
        default:
          color = 'primary'
      }
      return color
    },
    search (e) {},

    deleteObject (e, row) {
      e.stopPropagation()
      let confirmCallback = () => {
        this.loading = true
        let successCallback = (response) => {
          this.loading = false
          this.$notify({
            title: this.$t('msg.titleSuccess'),
            message: this.$t('registerInfo.msgDeleteSuccess'),
            type: 'success'
          })
          this.isReload = !this.isReload
        }
        let errorCallback = (error) => {
          this.loading = false
          if (error.response.status === 403) {
            this.$notify({
              title: this.$t('msg.titleError'),
              message: this.$t('registerInfo.msgDeleteErrorUser'),
              type: 'error'
            })
          }
          this.isReload = !this.isReload
        }
        let payload = {
          successCallback,
          errorCallback,
          id: row.id
        }
        this.$store.dispatch('registerInfo/deleteRegisterInfo', payload)
      }
      let payloadConfirm = {
        callback: confirmCallback,
        message: this.$t('registerInfo.msgConfirmDelete')
      }

      this.$store.commit('openConfirm', payloadConfirm)
    },
    goPath (path) {
      if (path) {
        this.$router.push(path)
      }
    }
  },

  watch: {
    config: {
      deep: true,
      handler: function () {
        this.index++
      }
    },
    idCustomerFilter (val) {
      if (val) {
        let index = this.listCustomer.findIndex(elm => elm.id === val)
        if (index >= 0) {
          this.listDomain = this.listCustomer[index].listDomain || []
          this.config.dataFilter.id_domain = this.listDomain.map(elm => { return elm.ID })
        }
      } else {
        this.listDomain = []
        this.config.dataFilter.id_domain = []
      }
    }
  }
}
</script>
