<template>
  <div class="pagination-old__paginator">
    <ul class="clearfix">
      <li @click="prev()">
        <a
            class="page-link"
            tabindex="-1"
            :class="{'point-auto': page===1}" >
          <i class="el-icon-arrow-left"></i>
        </a>
      </li>
      <li
          v-for="(page, index) in pageList"
          :key="index"
          :class="page.class">
        <a
            class="page-link"
            @click="goPage(page)">
          {{ page.value }}
        </a>
      </li>
      <li @click="next()">
        <a
            class="page-link"
            :class="{'point-auto': page===totalPage}">
          <i class="el-icon-arrow-right"></i>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { PER_PAGE_LIST } from '@/config/constants'

export default {
  name: 'pagination',
  props: {
    total: {
      type: Number,
      require: true
    },
    page: {
      type: Number,
      require: true
    },
    perPage: {
      type: Number,
      require: true
    }
  },

  data: function () {
    return {
      perPageList: PER_PAGE_LIST
    }
  },
  methods: {
    goPage (page) {
      if (page.class !== 'dot' && page.class !== 'active') {
        this.$emit('update:page', page.value)
      }
    },
    prev () {
      if (this.page > 1) {
        this.$emit('update:page', this.page - 1)
      }
    },
    next () {
      if (this.page < this.totalPage) {
        this.$emit('update:page', this.page + 1)
      }
    },
    onChange (value) {
      this.$emit('updatePerPage', value)
    }
  },
  computed: {
    totalPage: function () {
      return Math.ceil(this.total / this.perPage)
    },
    from: function () {
      return (this.page - 1) * this.perPage + 1
    },
    to: function () {
      let to = this.page * this.perPage
      return to > this.total ? this.total : to
    },
    pageList: function () {
      let pageList = []
      for (let i = 1; i <= this.totalPage; i++) {
        let page = null
        if (i === this.page) {
          page = {
            value: i,
            class: 'active point-auto--active'
          }
        } else if (i === 1 || i === this.totalPage || i === 2 || i === this.totalPage - 1 || i === this.page + 1 || i === this.page - 1 ||
            (i === 2 && this.page - i === 1) ||
            (i - this.page === 1 && this.totalPage - i === 1)) {
          page = {
            value: i,
            class: ''
          }
        } else if ((i < this.page && i - 1 > 1) || (i > this.page && this.totalPage - i > 1)) {
          if (pageList[pageList.length - 1].class !== 'dot') {
            page = {
              value: '...',
              class: 'dot'
            }
          }
        }
        if (page) {
          pageList.push(page)
        }
      }
      return pageList
    }
  }
}
</script>

<style lang="scss">
.pagination-old {
  font-size: 14px;
  padding: 7px 0px 0px;
  color: $sub-black-color;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .pagination__items__label{
    font-size: 13px;
    margin-right: 5px;
  }
  .pagination__items__result{
    margin-left: 6px;
    margin-right: 8px;

    font-size: 13px;

  }
  .el-input__inner{
    line-height: 20px;
    height: 20px;
    width: 50px;
    border-radius: 4px;
    padding-right: 15px !important;
    padding-left: 7px;
    font-size: 12px;
  }
  .el-input__icon {
    width: 15px;
    line-height: 20px;
  }
  &__items {
    .el-select {
      width: 30px !important;
    }
    float: left;

    > * {
      display: block;
      float: left;
    }

    &__label {
      margin-right: 10px;
      line-height: 20px;
    }

    &__select {
      width: 30px !important;
      position: relative;
      margin-right: 15px;
      .el-input {
        input {
          @include regular-font(14px);

          border: none !important;
          color: $primary-color;
          padding: 0;
          line-height: 20px;
          height: 20px;
        }

        .el-select__caret {
          @include compatible(transform, rotateZ(0)!important);

          width: 10px;
          height: 5px;

          &.is-reverse {
            @include compatible(transform, rotateZ(180deg)!important);
          }

          &:before {
            content: '';
          }
        }

        &__suffix {
          right: 0;
          top: 7px;
          width: 10px;
          height: 5px;
        }
      }
    }

    &__result {
      line-height: 20px;
      margin-left: 6px;
      display: inline-block;
    }
  }

  &__paginator {
    float: right;

    &__result {
      float: left;
      margin-right: 25px;
      line-height: 20px;
    }

    ul {
      display: -webkit-box;
      display: flex;
      padding-left: 0;
      list-style: none;
      //border-radius: 0.375rem;
      -webkit-box-pack: end !important;
      justify-content: flex-end !important;
      li {
        .page-link{
          border: 1px solid #dee2e6;
          display: -webkit-box;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          padding: 0;
          margin: 0 3px;
          border-radius: 4px !important;
          width: 36px;
          height: 36px;
          font-size: 0.875rem;
          cursor: pointer;
          background-color: #fff;
          &:hover{
            z-index: 2;
            text-decoration: none;
            background-color: #dee2e6;
            border-color: #dee2e6;
          }
        }

        &:last-child {
          margin: 0;
        }

        &.active {
          .page-link{
            z-index: 3;
            //color: #fff;
            background-color: #75b5ff;
            border-color: #75b5ff;
            box-shadow: 0 7px 14px #32325d1a, 0 3px 6px #00000014;
          }
        }

        &.dot {
          cursor: unset;
        }
      }
    }
  }
}
.point-auto{
  cursor: auto !important;
  background-color: #dee2e6 !important;
  &--active{
    cursor: auto;

    .page-link{
      cursor: auto !important;
      color: #ffffff !important;

    }
  }
}
</style>
