import auth from './auth'
import staff from './staff'
import plugin from './plugin'
import customer from './customer'
import registerInfo from './registerInfo'
export default {
  auth,
  staff,
  plugin,
  customer,
  registerInfo
}
