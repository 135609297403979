<template>
    <div class="box-search">
      <label class="mr-2"> {{$t('searchLabel')}}: </label>
      <input
          type="text"
          v-bind="$attrs"
          :placeholder="placeholder"
          v-on="inputListeners"
          class="form-control search-box__input"
          :value="value"
          @input="$emit('input', $event.target.value)">
    </div>
</template>
<script>
// Create search input
export default {
  name: 'custom-input',
  inheritAttrs: false,
  props: {
    value: [String, Number],
    label: {
      type: String,
      required: false,
      default: ''
    },
    classes: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      value1: '',
      isFocused: false,
      message: null,
      maxLength: 1000
    }
  },
  computed: {
    listeners () {
      return {
        ...this.$listeners,
        blur: this.onBlur,
        focus: this.onFocus
      }
    },
    mergedProps () {
      return {
        ...this.$attrs,
        ...this.$props
      }
    },
    inputListeners: function () {
      let vm = this
      return Object.assign({},
        this.$listeners,
        {
          input: function (event) {
            vm.$emit('input', event.target.value)
          }
        }
      )
    }
  },
  methods: {
    changeInput (e) {
      if (e.target.value.length <= this.maxLength) {
        this.$emit('input', e.target.value)
      } else {
        e.target.value = this.value
        this.$emit('input', this.value)
      }
    },
    onBlur () {
      this.isFocused = false
    },
    onFocus () {
      this.isFocused = true
    }
  }
}
</script>
<style lang="scss">
.box-search{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  label{
    margin-bottom: 0px;
    min-width: 70px;
    text-align: right;
  }
  input{
    height: 36px !important;

    &.search-box__input{
      min-width: 250px;
      font-size: .8rem;
    }
  }
}
</style>
