'use strict'
import Api from '@/api/abstract'
import { APP_TOKEN_NAME } from '../../config/constants'

export let GET_LIST_CUSTOMER = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/customer?${params.payload.query}`
  let headers = {
    Authorization: `${window.$cookies.get(APP_TOKEN_NAME)}`
  }
  return new Api(url, 'get', successCallback, errorCallback, { headers })
}

export let GET_CUSTOMER_BY_ID = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/customer/${params.payload.id}`
  let headers = {
    Authorization: `${window.$cookies.get(APP_TOKEN_NAME)}`
  }
  return new Api(url, 'get', successCallback, errorCallback, { headers })
}

export let CREATE_CUSTOMER = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/customer`
  let headers = {
    Authorization: `${window.$cookies.get(APP_TOKEN_NAME)}`
  }
  let data = params.payload.data
  return new Api(url, 'post', successCallback, errorCallback, { headers, data })
}

export let UPDATE_CUSTOMER = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/customer/${params.payload.id}`
  let headers = {
    Authorization: `${window.$cookies.get(APP_TOKEN_NAME)}`
  }
  let data = params.payload.data
  return new Api(url, 'put', successCallback, errorCallback, { headers, data })
}

export let DELETE_CUSTOMER = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/customer/${params.payload.id}`
  let headers = {
    Authorization: `${window.$cookies.get(APP_TOKEN_NAME)}`
  }
  return new Api(url, 'delete', successCallback, errorCallback, { headers })
}

export let CHANGE_STATUS_CUSTOMER = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/customer`
  let headers = {
    Authorization: `${window.$cookies.get(APP_TOKEN_NAME)}`
  }
  return new Api(url, 'PATCH', successCallback, errorCallback, { headers })
}

export let GET_ALL_LIST_CUSTOMER = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/master/all-customer`
  let headers = {
    Authorization: `${window.$cookies.get(APP_TOKEN_NAME)}`
  }
  return new Api(url, 'get', successCallback, errorCallback, { headers })
}

export let IMPORT_CUSTOMER = (params, successCallback, errorCallback) => {
  let url = `${process.env.VUE_APP_API_URL}/admin/customer/import-file-customer`
  let headers = {
    Authorization: `${window.$cookies.get(APP_TOKEN_NAME)}`,
    'Content-Type': 'multipart/form-data'
  }
  let data = params.payload.data
  return new Api(url, 'post', successCallback, errorCallback, { headers, data })
}
